import React, { useState, useEffect } from 'react';
import Card from './Card';
import '../styles/Dashboard.css';

const StudentsDash = ({ selectedDistrictInfo, boardView, dashboardData }) => {
    const [schoolData, setSchoolData] = useState({});

    const maxYear = Math.max(...Object.keys(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));

    // Helper functions
    const formatPrecision = (num, precision) => {
        return num ? parseFloat(num).toFixed(precision) : 'N/A';
    };

    const formatDollars = (num) => {
        return `$${Math.round(num).toLocaleString()}`;
    };

    function calculateAverage(array) {
        if (array.length === 0) {
          return 0;
        }
      
        const sum = array.reduce((acc, num) => acc + num, 0);
        return sum / array.length;
    }

    const calcYoYchange = (num_old, num_new) => {
        return (100 * (num_new - num_old) / num_old).toFixed(0);
    };

    const getArrowDirection = (num) => {
        if (num > 0) return "up";
        if (num < 0) return "down";
        return "flat";
    };

    selectedDistrictInfo['peer_STUDENTTEACHERRATIO_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['STUDENTS_PER_TEACHER_RATIO']));
    selectedDistrictInfo['peer_JANUARYMEMBERSHIP_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['JANUARY_MEMBERSHIP']));
    selectedDistrictInfo['peer_FRL_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['LUNCH_COUNT_FREE_REDUCED_PCT']));
    selectedDistrictInfo['peer_IEP_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['IEP_INCIDENCE_RATE']));


    return (
        <div className="dashboard">
            <div className="title">Students</div>

            <div className="dashboard-cards">
            <Card
                defaultSchool={selectedDistrictInfo}
                title="Student Teacher Ratio" 
                value={`${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].STUDENTS_PER_TEACHER_RATIO}` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].STUDENTS_PER_TEACHER_RATIO,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].STUDENTS_PER_TEACHER_RATIO) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${selectedDistrictInfo.peer_STUDENTTEACHERRATIO_avg || "0"}`}
                bgColor="#88b0d8"
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].STUDENTS_PER_TEACHER_RATIO,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].STUDENTS_PER_TEACHER_RATIO))}`}
                definition="The number of students per full-time equivalent teacher, a measure of class size and instructional resource allocation."
                graphMetric="STUDENTS_PER_TEACHER_RATIO"
                yAxisTitle='Ratio'
                boardView={boardView}
                graphData={dashboardData}
            />
            <Card
                defaultSchool={selectedDistrictInfo}
                title="January Membership" 
                value={`${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].JANUARY_MEMBERSHIP}` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].JANUARY_MEMBERSHIP,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].JANUARY_MEMBERSHIP) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_JANUARYMEMBERSHIP_avg, 0) || "0"}`}
                bgColor="#d8abc1"
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].JANUARY_MEMBERSHIP,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].JANUARY_MEMBERSHIP))}`}
                definition="The total number of students enrolled in the district as of the January student count, used for official reporting and funding allocations."
                graphMetric="JANUARY_MEMBERSHIP"
                yAxisTitle='Membership'
                boardView={boardView}
                graphData={dashboardData}
            />
            <Card
                defaultSchool={selectedDistrictInfo}
                title="Free & Reduced Lunch" 
                value={`${formatPrecision(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].LUNCH_COUNT_FREE_REDUCED_PCT, 0)}%` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].LUNCH_COUNT_FREE_REDUCED_PCT,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].LUNCH_COUNT_FREE_REDUCED_PCT) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_FRL_avg,0) || "0"}%`}
                bgColor="#87b6a7" 
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].LUNCH_COUNT_FREE_REDUCED_PCT,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].LUNCH_COUNT_FREE_REDUCED_PCT))}`}
                definition="The percentage of students eligible for free or reduced-price lunch programs, a common indicator of the socioeconomic demographics of the student population." 
                graphMetric="LUNCH_COUNT_FREE_REDUCED_PCT"
                yAxisTitle='Percent (%)'
                boardView={boardView}
                graphData={dashboardData}
            />
            <Card
                defaultSchool={selectedDistrictInfo}
                title="IEP Incidence Rate" 
                value={`${formatPrecision(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].IEP_INCIDENCE_RATE,0)}%` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].IEP_INCIDENCE_RATE,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].IEP_INCIDENCE_RATE) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_IEP_avg,0) || "0"}%`}
                bgColor="#f79f79"
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].IEP_INCIDENCE_RATE,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].IEP_INCIDENCE_RATE))}`}
                definition="The percentage of students with Individualized Education Programs (IEPs), signifying the proportion of students receiving special education services."
                graphMetric="IEP_INCIDENCE_RATE"
                yAxisTitle='Percent (%)'
                boardView={boardView}
                graphData={dashboardData}
            />
            </div>
        </div>
    );
};

export default StudentsDash;
