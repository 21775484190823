import React, { useState } from 'react';
import '../styles/AuthenticationCard.css';
import logo from '../logo.png'; 
import { auth } from '../firebaseConfig';
import { createUserWithEmailAndPassword, 
         signInWithEmailAndPassword, 
         signInWithPopup, 
         GoogleAuthProvider, 
         OAuthProvider } 
  from 'firebase/auth';

const AuthenticationCard = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isNewUser, setIsNewUser] = useState(false);

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  const handleMicrosoftSignIn = async () => {
    try {
      const provider = new OAuthProvider('microsoft.com');
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error signing in with Microsoft:', error);
    }
  };

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleEmailPasswordSignIn = async () => {
    try {
      if (isNewUser) {
        // Create a new account
        await createUserWithEmailAndPassword(auth, email, password);
        console.log('Account created successfully');
      } else {
        // Sign in with an existing account
        await signInWithEmailAndPassword(auth, email, password);
        console.log('Logged in successfully');
      }
    } catch (error) {
      console.error('Error with email and password authentication:', error);
    }
  };

  return (
    <div className="authentication-container">
      <img src={logo} alt="BalanceHQ Company Logo" className="login-logo" />
      <div className="authentication-card">
        <div className="authentication-header">
          <h2>{isNewUser ? 'Sign Up' : 'Sign In'}</h2>
          <p>Choose your preferred sign-in method</p>
        </div>

        <div className="button-container">
          <button 
            className="authentication-button"
            onClick={handleGoogleSignIn}
          >
            <svg
              className="button-icon"
              viewBox="0 0 24 24"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                fill="#4285F4"
              />
              <path
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                fill="#34A853"
              />
              <path
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                fill="#FBBC05"
              />
              <path
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                fill="#EA4335"
              />
            </svg>
            <span>Continue with Google</span>
          </button>

          <button 
            className="authentication-button"
            onClick={handleMicrosoftSignIn}
          >
            <svg
              className="button-icon"
              viewBox="0 0 23 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M11 1H1v10h10V1z" fill="#F25022" />
                <path d="M22 1H12v10h10V1z" fill="#7FBA00" />
                <path d="M11 12H1v10h10V12z" fill="#00A4EF" />
                <path d="M22 12H12v10h10V12z" fill="#FFB900" />
            </svg>
            <span>Continue with Microsoft</span>
          </button>
        </div>

        <div className="email-password-container">
          <input 
            type="email" 
            placeholder="Email" 
            value={email}
            onChange={handleEmailChange}
          />
          <input 
            type="password" 
            placeholder="Password" 
            value={password}
            onChange={handlePasswordChange}
          />
          <button 
            className="authentication-button"
            onClick={handleEmailPasswordSignIn}
          >
            {isNewUser ? 'Create Account' : 'Login'}
          </button>
          <p 
            className="toggle-auth-mode"
            onClick={() => setIsNewUser(!isNewUser)}
          >
            {isNewUser ? 'Already have an account? Sign in' : 'New user? Create an account'}
          </p>
        </div>

        <p className="footer-text">
          By continuing, you agree to our Terms of Service and Privacy Policy.
        </p>
      </div>
    </div>
  );
};

export default AuthenticationCard;