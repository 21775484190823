import React, { useState, useEffect } from 'react';
import { database } from '../firebaseConfig';
import { ref, get, set, remove } from "firebase/database";
import { FaPlus, FaTimes, FaSave, FaTrash } from 'react-icons/fa';
import '../styles/PeerSelection.css';

const PeerSelection = ({ selectedDistrictInfo, updatePeerGroupOptions }) => {
  const [data, setData] = useState({});
  const [districtMapData, setDistrictMapData] = useState({});
  const [searchDistrict, setSearchDistrict] = useState('');
  const [newListName, setNewListName] = useState('');
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [isCreatingNewGroup, setIsCreatingNewGroup] = useState(false);
  const [message, setMessage] = useState('');
  
  useEffect(() => {
    const fetchPeerDefinitionsData = async () => {
      const snapshot = await get(ref(database, '/peerDefinitions'));
      if (snapshot.exists()) setData(snapshot.val());
      const districtMap = await get(ref(database, '/districtCodeMap'));
      if (districtMap.exists()) setDistrictMapData(districtMap.val());
    };
    fetchPeerDefinitionsData();
  }, []);

  const availableCodes = Object.keys(districtMapData);
  const availableDistricts = [...new Set(availableCodes.map(item => districtMapData[item]))];

  const getKeyByValue = (obj, value) => Object.keys(obj).find(key => obj[key] === value);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchDistrict(value);
    setSuggestions(value ? availableDistricts.filter(d => d.toLowerCase().startsWith(value.toLowerCase())) : []);
  };

  const handleAddSchool = (distName) => {
    if (distName && !selectedDistricts.includes(distName)) {
      setSelectedDistricts([...selectedDistricts, distName]);
      setSearchDistrict('');
      setSuggestions([]);
    }
  };

  const handleRemoveSchool = (distName) => {
    setSelectedDistricts(selectedDistricts.filter(district => district !== distName));
  };

  const handleGroupSelection = (e) => {
    const value = e.target.value;
    setSelectedGroup(value);
    setMessage('');
    if (value === "Create New +") {
      setIsCreatingNewGroup(true);
      setSelectedDistricts([]);
    } else {
      setIsCreatingNewGroup(false);
      const districtCodes = data[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][value] || [];
      setSelectedDistricts(districtCodes.map(code => districtMapData[code]));
    }
  };

  const handleSaveList = async () => {
    if ((isCreatingNewGroup && newListName && selectedDistricts.length > 0) || (!isCreatingNewGroup && selectedDistricts.length > 0)) {
      const updatedData = { ...data };
      const selectedDistrictCodes = selectedDistricts.map(item => parseInt(getKeyByValue(districtMapData, item)));

      if (isCreatingNewGroup) {
        updatedData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][newListName] = selectedDistrictCodes;
      } else {
        updatedData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][selectedGroup] = selectedDistrictCodes;
      }

      try {
        await set(ref(database, `/peerDefinitions/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}`), updatedData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]);
        setMessage('Changes saved successfully!');
        setTimeout(() => setMessage(''), 3000);
        updatePeerGroupOptions(Object.keys(updatedData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));
        setNewListName('');
        setSelectedDistricts([]);
        setIsCreatingNewGroup(false);
      } catch (error) {
        console.error("Error saving data: ", error);
      }
    }
  };

  const handleDeleteGroup = async () => {
    if (selectedGroup && !isCreatingNewGroup) {
      const updatedData = { ...data };
      delete updatedData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][selectedGroup];

      try {
        await set(ref(database, `/peerDefinitions/${selectedDistrictInfo['DISTRICT_COUNTY_CODE']}`), updatedData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]);
        setMessage('Group deleted successfully!');
        setTimeout(() => setMessage(''), 3000);
        updatePeerGroupOptions(Object.keys(updatedData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));
        setSelectedGroup('');
        setSelectedDistricts([]);
      } catch (error) {
        console.error("Error deleting group: ", error);
      }
    }
  };

  return (
    <div className="container">
      <h2 className="header">Peer Group Manager</h2>

      <div className="cardPeer-container">
        <div className="cardPeer">
          <h3>Create or Edit Peer Group</h3>
          <select className="dropdown" id="groupSelection" value={selectedGroup} onChange={handleGroupSelection}>
            <option value="">Select An Option</option>
            <option value="Create New +">Create New +</option>
            {Object.keys(data[selectedDistrictInfo['DISTRICT_COUNTY_CODE']] || {}).map((groupName, index) => (
              <option key={index} value={groupName}>{groupName}</option>
            ))}
          </select>
          {isCreatingNewGroup && (
            <input
              className="input"
              type="text"
              value={newListName}
              onChange={(e) => setNewListName(e.target.value)}
              placeholder="Name Your New Group Here"
            />
          )}
        </div>

        <div className="cardPeer">
          <h3>Search and Add Peers</h3>
          <input
            className="input"
            type="text"
            value={searchDistrict}
            onChange={handleSearchChange}
            placeholder="Search For And Add A District Here"
            disabled={!selectedGroup || selectedGroup === "Select"}
          />

          {suggestions.length > 0 && (
            <ul className="suggestions-list">
              {suggestions.map((name, index) => (
                <li key={index} onClick={() => handleAddSchool(name)}>
                  {name}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="cardPeer">
          <h3>Group Peers</h3>
          <ul className="selected-list">
            {selectedDistricts.map((district, index) => (
              <li key={index} className="selected-item">
                {district} 
                <button className="btn remove" onClick={() => handleRemoveSchool(district)}><FaTimes /></button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="button-container">
        <button className="btn save" onClick={handleSaveList}><FaSave /> {isCreatingNewGroup ? 'Save New Group' : 'Save Changes'}</button>

        {!isCreatingNewGroup && selectedGroup && (
          <button className="btn delete" onClick={handleDeleteGroup}><FaTrash /> Delete Group</button>
        )}
      </div>

    </div>
  );
};

export default PeerSelection;
