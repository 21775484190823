import React, {useState} from 'react';
import Card from './Card';
import '../styles/Dashboard.css';
//import DrilldownTable from './DrilldownTable';
import ReportDrilldownTable from './ReportDrilldown';

const MonthlyReport = ({ selectedDistrictInfo, boardView, dashboardData,currentBudget }) => {

    const [highlights, setHighlights] = useState([
        "Insert & edit highlights by clicking here...",
        "Add a new row by hitting \"enter\""
    ]);

    const maxYear = Math.max(...Object.keys(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));

    const desc = 
        selectedDistrictInfo['DISTRICT_NAME'] === "Fayette R-III"
            ? "Current Budget" 
            : selectedDistrictInfo['DISTRICT_NAME'] === "Washington"
                ? "Current Budget (Upload Current Data to View)" 
                : "Current Budget (Upload Current Data to View)";
    

    function calculateAverage(array) {
        if (array.length === 0) {
          return 0;
        }
      
        const sum = array.reduce((acc, num) => acc + num, 0);
        return sum / array.length;
    }

    selectedDistrictInfo['peer_TEACHSALARY_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['TEACHER_SALARY_AVG_REG_TERM']));
    selectedDistrictInfo['peer_STUDENTTEACHERRATIO_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['STUDENTS_PER_TEACHER_RATIO']));
    selectedDistrictInfo['peer_STUDENTADMINRATIO_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['STUDENTS_PER_ADMIN_RATIO']));
    selectedDistrictInfo['peer_PROFDEVEL_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['BUDGET_PERCENT_PROFESSIONAL_DEVELOPMENT']));

    
    const handleHighlightChange = (index, value) => {
        const newHighlights = [...highlights];
        newHighlights[index] = value;
        setHighlights(newHighlights);
    };

    const handleKeyPress = (e, index) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const newHighlights = [...highlights];
            newHighlights.splice(index + 1, 0, "");
            setHighlights(newHighlights);
        } else if (e.key === 'Backspace' && highlights[index] === "") {
            e.preventDefault();
            const newHighlights = [...highlights];
            newHighlights.splice(index, 1);
            setHighlights(newHighlights);
        }
    };

    return (
        <div className="dashboard">
            <div className="title" style={{fontSize:'40px'}}>October Board Budget Report</div>

            <div className="dashboard-cards">
                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="OpEx Fund Balance"
                    value="57%"
                    YoY=""
                    smallText="Peers: 40%"
                    bgColor="#88b0d8"
                    arrowDirection=""
                    definition="Percent of the annual budget that can be paid with cash on hand"
                    graphMetric="TEACHER_SALARY_AVG_REG_TERM"
                    yAxisTitle='Dollars ($)'
                    boardView={boardView}
                    graphData={dashboardData}
                    isExpandable={false}
                />
                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="Cash On Hand" 
                    value= {
                        selectedDistrictInfo['DISTRICT_NAME'] === 'Fayette R-III' 
                            ? "$5.7m" 
                            : selectedDistrictInfo['DISTRICT_NAME'] === 'Washington' 
                                ? "$47m" 
                                : "%X"
                    }
                    YoY=""
                    smallText=""
                    bgColor="#d8abc1"
                    arrowDirection=""
                    definition="The amount non-restricted cash in our bank accounts"
                    graphMetric="STUDENTS_PER_TEACHER_RATIO"
                    yAxisTitle='Ratio'
                    boardView={boardView}
                    graphData={dashboardData}
                    isExpandable={false}
                />
                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="YTD OpEx %" 
                    value="32%"
                    YoY=""
                    smallText="Peers: 35%"
                    bgColor="#87b6a7"
                    arrowDirection=""
                    definition="Percent of our annual operating budget that has been spent month to date"
                    graphMetric="STUDENTS_PER_ADMIN_RATIO"
                    yAxisTitle='Ratio'
                    boardView={boardView}
                    graphData={dashboardData}
                    isExpandable={false}
                />
                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="YTD Revenue %" 
                    value="21%"
                    YoY=""
                    smallText="Peers 20%"
                    bgColor="#f79f79"
                    arrowDirection=""
                    definition="Percent of our annual revenue that has been received month to date"
                    graphMetric="BUDGET_PERCENT_PROFESSIONAL_DEVELOPMENT"
                    yAxisTitle='Percent (%)'
                    boardView={boardView}
                    graphData={dashboardData}
                    isExpandable={false}
                />
            </div>
            <div>
                <div className='key-highlights'>
                    <h1 className="title">Key Highlights</h1>
                    <ul>
                        {highlights.map((highlight, index) => (
                            <li key={index} style={{ fontSize: '25px', marginBottom: '1%', marginLeft: '5%' }}>
                                <input
                                    type="text"
                                    value={highlight}
                                    onChange={(e) => handleHighlightChange(index, e.target.value)}
                                    onKeyDown={(e) => handleKeyPress(e, index)}
                                    style={{
                                        fontSize: '25px',
                                        width: '90%',
                                        padding: '5px',
                                        border: 'none', // No border
                                        outline: 'none', // No outline on focus
                                        background: 'transparent' // Transparent background
                                    }}
                                />
                            </li>
                        ))}
                    </ul>
                </div>

            {/* Table Integration */}
          <h1 className="title">{desc}</h1>
          <div className="drilldownTableContainer" style={{marginBottom:"15%"}}>
            {console.log('Current Budget Being Passed In: ',currentBudget)}
            <ReportDrilldownTable 
              data={currentBudget} 
              year={"2025"} 
              showGraph={false} 
              selectedSchool={selectedDistrictInfo['DISTRICT_NAME']}
              showGraphOption={false}
            />
          </div>
            </div>
        </div>
        
    );
};

export default MonthlyReport;

