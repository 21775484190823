import React, { useState } from 'react';
import LineGraph from './LineGraph';
import '../styles/DrilldownTable.css';

const formatDollars = (num) => {
  return `$${Math.round(num).toLocaleString()}`;
};

const ReportDrilldownTable = ({ data, year, selectedSchool, showGraphOption = true }) => {
  const [expandedLabels, setExpandedLabels] = useState([]);
  const [selectedGraphLabel, setSelectedGraphLabel] = useState(null);
  const [viewMode, setViewMode] = useState('graph');

  const filteredData = data.filter((row) => row.YEAR === year);

  // Group data by label for ANNUAL_BUDGET, SEPTEMBER, and YEAR_TO_DATE values
  const groupDataByLabel = (rows) => {
    return rows.reduce((acc, item) => {
      const { LABEL, ANNUAL_BUDGET, SEPTEMBER, YEAR_TO_DATE } = item;
      if (!acc[LABEL]) {
        acc[LABEL] = {};
      }
      acc[LABEL].ANNUAL_BUDGET = ANNUAL_BUDGET;
      acc[LABEL].SEPTEMBER = SEPTEMBER;
      acc[LABEL].YEAR_TO_DATE = YEAR_TO_DATE;
      return acc;
    }, {});
  };

  // Calculate % of Total for September and Year to Date columns
  const calculatePercentage = (value, total) => {
    if (!total || total === 0) return '0%';
    return `${((value / total) * 100).toFixed(2)}%`;
  };

  // Handle row click for expanding/collapsing
  const handleRowClick = (label) => {
    setExpandedLabels((prevExpandedLabels) =>
      prevExpandedLabels.includes(label)
        ? prevExpandedLabels.filter((l) => l !== label)
        : [...prevExpandedLabels, label]
    );
  };

  // Determine if the label is the final child in drilldown
  const isFinalChild = (label) => {
    return filteredData.every((item) => item.PARENT !== label);
  };

  // Handle graph display toggle
  const handleShowGraphClick = (label) => {
    setSelectedGraphLabel((prevLabel) => (prevLabel === label ? null : label));
  };

  // Handle view mode toggle (graph or raw data)
  const toggleViewMode = () => {
    setViewMode(viewMode === 'graph' ? 'spreadsheet' : 'graph');
  };

  // Render rows recursively for drill-down
  const renderRows = (parentLabel, level = 0) => {
    const currentRows = filteredData.filter((row) => row.PARENT === parentLabel);
    if (!currentRows.length) return null;

    const groupedCurrentRows = groupDataByLabel(currentRows);
    const backgroundColors = ['#e3f2fd', '#bbdefb', '#90caf9', '#64b5f6'];

    return Object.entries(groupedCurrentRows).map(([label, values]) => {
      const backgroundColor = backgroundColors[level % backgroundColors.length];

      return (
        <React.Fragment key={label}>
          <tr
            onClick={() => handleRowClick(label)}
            style={{ cursor: 'pointer', backgroundColor, position: 'relative', height: '50px' }}
          >
            <td
              style={{
                paddingLeft: `${level * 20}px`,
                textAlign: 'left',
                fontSize: '18px',
                padding: '15px',
                whiteSpace: 'pre',
              }}
            >
              {`${'    '.repeat(level)}`}
              {isFinalChild(label) && (
                <span style={{ marginRight: '5px', fontSize: '24px', fontWeight: 'bold', color: 'black' }}>–</span>
              )}
              {!isFinalChild(label) && (
                <span
                  className="expand-arrow"
                  style={{
                    display: 'inline-block',
                    transform: expandedLabels.includes(label) ? 'rotate(90deg)' : 'rotate(0deg)',
                    transition: 'transform 0.25s ease-in-out',
                    marginRight: '5px',
                  }}
                >
                  ▶
                </span>
              )}
              {label}
              {showGraphOption && (
                <span
                  role="img"
                  aria-label="Show Graph"
                  style={{ cursor: 'pointer', marginLeft: '3px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowGraphClick(label);
                  }}
                >
                  📈
                </span>
              )}
            </td>
            {['ANNUAL_BUDGET', 'SEPTEMBER', 'YEAR_TO_DATE'].map((column, index) => (
              <React.Fragment key={`${label}-${column}`}>
                <td
                  style={{
                    fontSize: '16px',
                    padding: '15px',
                    width: '150px',
                    textAlign: 'center',
                  }}
                >
                  <span style={values[column] > 10000000000 ? { backgroundColor: '#ffcccc' } : {fontWeight:'bold'}}>
                    {formatDollars(values[column])}
                  </span>
                </td>
                {column === 'SEPTEMBER' && (
                  <td
                    style={{
                      fontSize: '16px',
                      padding: '15px',
                      width: '150px',
                      textAlign: 'center',
                    }}
                  >
                    {calculatePercentage(values[column], values['ANNUAL_BUDGET'])}
                  </td>
                )}
                {column === 'YEAR_TO_DATE' && (
                  <td
                    style={{
                      fontSize: '16px',
                      padding: '15px',
                      width: '150px',
                      textAlign: 'center',
                    }}
                  >
                    {calculatePercentage(values[column], values['ANNUAL_BUDGET'])}
                  </td>
                )}
              </React.Fragment>
            ))}
          </tr>

          {selectedGraphLabel === label && (
            <tr>
              <td colSpan={6} style={{ padding: '20px', position: 'relative' }}>
                <div style={{ width: '100%', height: '500px', overflow: 'hidden', position: 'relative' }}>
                  {viewMode === 'graph' ? (
                    <div style={{ width: '100%', height: '90%' }}>
                      <LineGraph
                        data={getGraphData(label)}
                        metric="ANNUAL_BUDGET"
                        selectedDistricts={[selectedSchool]}
                        yAxisLabel="Dollars ($)"
                        graphTitle={label}
                      />
                    </div>
                  ) : (
                    <div>
                      <table style={{ backgroundColor: '#eee', border: '10px solid black', margin: '10px auto' }}>
                        <thead>
                          <tr>
                            <th style={{ textAlign: 'center', padding: '10px' }}>Year</th>
                            {['ANNUAL_BUDGET', 'SEPTEMBER', 'YEAR_TO_DATE'].map((column) => (
                              <th key={column} style={{ textAlign: 'center', padding: '10px' }}>{column}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {getRawData(label).map((row, index) => (
                            <tr key={index}>
                              <td style={{ textAlign: 'center', padding: '10px' }}>{row.YEAR}</td>
                              {['ANNUAL_BUDGET', 'SEPTEMBER', 'YEAR_TO_DATE'].map((column) => (
                                <td key={column} style={{ textAlign: 'center', padding: '10px' }}>{row[column]}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <button onClick={toggleViewMode} style={{ position: 'absolute', bottom: '5px', right: '10px' }}>
                  {viewMode === 'graph' ? 'View Raw Data' : 'View Graph'}
                </button>
              </td>
            </tr>
          )}

          {expandedLabels.includes(label) && renderRows(label, level + 1)}
        </React.Fragment>
      );
    });
  };

  const getGraphData = (label) => {
    return data
      .filter((row) => row.LABEL === label)
      .map((row) => ({
        DISTRICT_NAME: row.DISTRICT_NAME,
        YEAR: row.YEAR,
        ANNUAL_BUDGET: row.ANNUAL_BUDGET,
      }));
  };

  const getRawData = (label) => {
    const years = [...new Set(data.map((row) => row.YEAR))].sort((a, b) => b - a);
    const rawData = years.map((year) => {
      const row = { YEAR: year };
      const dataItem = data.find(
        (d) => d.LABEL === label && d.YEAR === year
      );
      row['ANNUAL_BUDGET'] = dataItem ? dataItem.ANNUAL_BUDGET : 0;
      row['SEPTEMBER'] = dataItem ? dataItem.SEPTEMBER : 0;
      row['YEAR_TO_DATE'] = dataItem ? dataItem.YEAR_TO_DATE : 0;
      return row;
    });
    return rawData;
  };

  return (
    <div>
      <table
        border="1"
        style={{
          borderCollapse: 'collapse',
          width: '100%',
          borderRadius: '10px',
          overflow: 'hidden',
          fontSize: '18px',
          border: '10px solid black',
          margin: '10px 0',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
          backgroundColor: '#eee'
        }}
      >
        <thead>
          <tr>
            <th style={{ borderTopLeftRadius: '10px', padding: '15px', textAlign: 'center' }}>Item</th>
            <th style={{ padding: '15px' }}>Annual Budget</th>
            <th style={{ padding: '15px' }}>September</th>
            <th style={{ padding: '15px' }}>% of Annual Budget (September)</th>
            <th style={{ padding: '15px' }}>Year to Date</th>
            <th style={{ borderTopRightRadius: '10px', padding: '15px' }}>% of Annual Budget (YTD)</th>
          </tr>
        </thead>
        <tbody>{renderRows('Self')}</tbody>
      </table>
    </div>
  );
};

export default ReportDrilldownTable;
