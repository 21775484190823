import React, { useState, useEffect } from 'react';
import { database } from './firebaseConfig';
import { ref, get } from "firebase/database";
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import BudgetDash from './components/BudgetDash';
import FacultyDash from './components/FacultyDash';
import StudentsDash from './components/StudentsDash';
import AcademicsDash from './components/AcademicsDash';
import RevenueDash from './components/RevenueDash';
import DebtDash from './components/DebtDash';
import ForecastDash from './components/ForecastDash';
import SalarySchedule from './components/SalarySchedule';
import Transportation from './components/Transportation';
import ChatBot from './components/ChatBot';
import Header from './components/Header';
import PeerSelection from './components/PeerSelection';
import AuthWrapper from './components/AuthWrapper';
import LoadingPage from './components/LoadingPage';
import './App.css';
import MonthlyReport from './components/MonthlyReport';

function App() {
    // State declarations
    const [isFirstLogin, setIsFirstLogin] = useState(true);
    const [uniqueDistrictNames, setUniqueDistrictNames] = useState([]);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [userFound, setUserFound] = useState(false);
    const [appState, setAppState] = useState({
        isLoading: true,
        error: null,
        data: {
            activeComponent: 'Overview',
            selectedDistrict: 'Fayette R-III',
            selectedDistrictInfo: {},
            peerDistrictInfo: {},
            peerGroupOptions: [],
            selectedPeerGroup: 'Default',
            peerGroupData: {},
            boardView: false,
            districtMap: {},
            dashboardData: {},
            drilldownData: {},
            currentBudget: {}
        }
    });

    function expandArray(data) {
        const result = [];
      
        data.forEach((item) => {
          // Iterate over the keys of each object
          for (const key in item) {
            // Check if the key is not 'DISTRICT_COUNTY_CODE' or 'YEAR'
            if (key !== 'DISTRICT_COUNTY_CODE' && key !== 'YEAR' && key !== 'DISTRICT_NAME') {
              // Push a new object to the result array with the desired structure
              result.push({
                DISTRICT_NAME: item.DISTRICT_NAME,
                YEAR: item.YEAR,
                LABEL: key,
                PARENT: item[key].PARENT,
                TOTAL_ALL_FUNDS: item[key].TOTAL_ALL_FUNDS
              });
            }
          }
        });
      
        return result;
      }

    function budgetExpandArray(data) {
        const result = [];
      
        data.forEach((item) => {
          // Iterate over the keys of each object
          for (const key in item) {
            // Check if the key is not 'DISTRICT_COUNTY_CODE' or 'YEAR'
            if (key !== 'DISTRICT_COUNTY_CODE' && key !== 'YEAR' && key !== 'DISTRICT_NAME') {
              // Push a new object to the result array with the desired structure
              result.push({
                DISTRICT_NAME: item.DISTRICT_NAME,
                YEAR: item.YEAR,
                LABEL: key,
                PARENT: item[key].PARENT,
                ANNUAL_BUDGET: item[key].ANNUAL_BUDGET,
                SEPTEMBER: item[key].SEPTEMBER,
                YEAR_TO_DATE: item[key].YEAR_TO_DATE
              });
            }
          }
        });
      
        return result;
      }
    

    // Helper function
    const getKeyByValue = (obj, value) => {
        return Object.keys(obj).find(key => obj[key] === value);
    };

    // Main data fetching function
    const initializeAppData = async (districtName = null) => {
        try {
            setAppState(prev => ({ ...prev, isLoading: true }));
            
            // Store the district we want to use
            const targetDistrict = districtName || appState.data.selectedDistrict;

            // 1. Fetch district map if we don't have it
            let districtMap = appState.data.districtMap;
            if (Object.keys(districtMap).length === 0) {
                const districtMapSnapshot = await get(ref(database, '/districtCodeMap'));
                if (!districtMapSnapshot.exists()) {
                    throw new Error('District map not found');
                }
                districtMap = districtMapSnapshot.val();
            }
            setUniqueDistrictNames([... new Set(Object.values(districtMap))])

            // 2. Fetch peer groups using district map
            const selectedDistrictCode = getKeyByValue(districtMap, targetDistrict);
            const peerGroupsSnapshot = await get(ref(database, `/peerDefinitions/${selectedDistrictCode}`));
            if (!peerGroupsSnapshot.exists()) {
                throw new Error('Peer groups not found');
            }

            const peerGroups = peerGroupsSnapshot.val();
            const processedPeerGroups = {};

            // Process peer groups
            for (const key in peerGroups) {
                const ids = peerGroups[key];
                const names = ids.map(id => districtMap[id] || "Unknown");
                processedPeerGroups[key] = {
                    names,
                    IDs: ids
                };
            }

            // 3. Fetch dashboard data for selected district and peers
            const selectedPeerGroup = 'Default';
            const peerIDs = processedPeerGroups[selectedPeerGroup].IDs;
            const dashboardDataPromises = [...peerIDs, selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/mainDashSummary/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });

            const dashboardResults = await Promise.all(dashboardDataPromises);
            const dashboardData = Object.fromEntries(dashboardResults.filter(([_, data]) => data !== null));

            // 4. Fetch drilldown data for selected district and peers
            const drilldownDataPromises = [...peerIDs, selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/expenseDrilldown/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });

            const drilldownResults = await Promise.all(drilldownDataPromises);
            const drilldownDataJSON = Object.fromEntries(drilldownResults.filter(([_, data]) => data !== null));

            const drilldownDataArray = Object.entries(drilldownDataJSON).flatMap(([key, values]) => 
                Object.entries(values).map(([YEAR, details]) => ({
                  DISTRICT_COUNTY_CODE: key,
                  YEAR,
                  ...details
                }))
              );

            for(let i = 0; i<drilldownDataArray.length; i++){
                drilldownDataArray[i]['DISTRICT_NAME'] = drilldownDataArray[i]['Admin Expense']['DISTRICT_NAME'];
            }

            const drilldownData = expandArray(drilldownDataArray);

            ////////////////////
            // 5. Fetch drilldown data for selected district and peers
            const currentBudgetPromises = [selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/currentBudget/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });
            
            const currentResults = await Promise.all(currentBudgetPromises);
            const currentBudgetJSON = Object.fromEntries(currentResults.filter(([_, data]) => data !== null));
            
            const currentBudgetArray = Object.entries(currentBudgetJSON).flatMap(([key, values]) => 
                Object.entries(values).map(([YEAR, details]) => {
                    return {
                        DISTRICT_COUNTY_CODE: key,
                        YEAR,
                        ANNUAL_BUDGET: details.ANNUAL_BUDGET || 0,
                        SEPTEMBER: details.SEPTEMBER || 0,
                        YEAR_TO_DATE: details.YEAR_TO_DATE || 0,
                        ...details
                    };
                })
            );
            for (let i = 0; i < currentBudgetArray.length; i++) {
                currentBudgetArray[i]['DISTRICT_NAME'] = targetDistrict;
            }
            const currentBudget = budgetExpandArray(currentBudgetArray);
            /////////////////////

            // Update all state at once
            setAppState(prev => ({
                isLoading: false,
                error: null,
                data: {
                    ...prev.data,
                    selectedDistrict: targetDistrict,
                    districtMap,
                    peerGroupData: processedPeerGroups,
                    peerGroupOptions: Object.keys(peerGroups),
                    selectedPeerGroup,
                    peerDistrictInfo: {
                        IDs: processedPeerGroups[selectedPeerGroup].IDs,
                        names: processedPeerGroups[selectedPeerGroup].names
                    },
                    selectedDistrictInfo: {
                        DISTRICT_NAME: targetDistrict,
                        DISTRICT_COUNTY_CODE: getKeyByValue(districtMap, targetDistrict),
                        peerGroupOptions: {
                            IDs: processedPeerGroups[selectedPeerGroup].IDs,
                            names: processedPeerGroups[selectedPeerGroup].names
                        },
                        selectedPeerGroup: selectedPeerGroup
                    },
                    dashboardData,
                    drilldownData,
                    currentBudget
                }
            }));

        } catch (error) {
            console.error('Error initializing app:', error);
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                error: error.message
            }));
        }
    };

    // Initial data load
    useEffect(() => {
        initializeAppData();
    }, []);

    const updateDistrict = async (newDistrict) => {
        // Only call `initializeAppData` if the district changes
        if (newDistrict !== appState.data.selectedDistrict) {
            await initializeAppData(newDistrict);
            setAppState(prev => ({
                ...prev,
                data: { ...prev.data, selectedDistrict: newDistrict }
            }));
        }
    };

    // Handle state changes
    const handleDistrictChange = async (event) => {
        const newDistrict = event.target.value;
        updateDistrict(newDistrict);
    };

    const handlePeerGroupChange = async (event) => {
        
        const newPeerGroup = event.target.value;

        // Update the state with the new peer group selection
        setAppState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                selectedPeerGroup: newPeerGroup,
                peerDistrictInfo: {
                    IDs: prev.data.peerGroupData[newPeerGroup].IDs,
                    names: prev.data.peerGroupData[newPeerGroup].names
                },
                selectedDistrictInfo: {
                    DISTRICT_NAME: prev.data.selectedDistrictInfo.DISTRICT_NAME,
                    DISTRICT_COUNTY_CODE: prev.data.selectedDistrictInfo.DISTRICT_COUNTY_CODE,
                    peerGroupOptions: {
                        IDs: prev.data.peerGroupData[newPeerGroup].IDs,
                        names: prev.data.peerGroupData[newPeerGroup].names
                    },
                },
            },
            
        }));

        

        // Fetch new dashboard data for the selected peer group
        await fetchDashboardDataForPeerGroup(newPeerGroup);
        await fetchDrilldownDataForPeerGroup(newPeerGroup);
    };

    const handleLogin = async (email) => {
        if (!isFirstLogin) return;

        const userRef = ref(database, `/userPermissions/`);
        const userSnapshot = await get(userRef);

        const userData = userSnapshot.val();
        const userPermissions = Object.values(userData).find(user => user.email === email.toLowerCase());

        if (userPermissions) {
            setUserFound(true);

            const { permissions } = userPermissions;

            if (permissions.district === 'All') {
                setSuperAdmin(true);
            } else if (appState.data.selectedDistrict !== permissions.district) {
                await initializeAppData(permissions.district);
            }
        }

        setIsFirstLogin(false);

        return;
    };
    
    const fetchDashboardDataForPeerGroup = async (peerGroup) => {
        try {
            setAppState(prev => ({ ...prev, isLoading: true }));
    
            // Get the peer group IDs from state
            const peerIDs = appState.data.peerGroupData[peerGroup].IDs;
            const selectedDistrictCode = getKeyByValue(appState.data.districtMap, appState.data.selectedDistrict);
    
            // Fetch dashboard data for the selected district and peer group
            const dashboardDataPromises = [...peerIDs, selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/mainDashSummary/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });
    
            const dashboardResults = await Promise.all(dashboardDataPromises);
            const dashboardData = Object.fromEntries(dashboardResults.filter(([_, data]) => data !== null));
    
            // Update the state with the new dashboard data
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                data: {
                    ...prev.data,
                    dashboardData
                }
            }));
        } catch (error) {
            console.error('Error fetching dashboard data for peer group:', error);
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                error: error.message
            }));
        }
    };

    const fetchDrilldownDataForPeerGroup = async (peerGroup) => {
        try {
            setAppState(prev => ({ ...prev, isLoading: true }));
    
            // Get the peer group IDs from state
            const peerIDs = appState.data.peerGroupData[peerGroup].IDs;
            const selectedDistrictCode = getKeyByValue(appState.data.districtMap, appState.data.selectedDistrict);
    
            // Fetch dashboard data for the selected district and peer group
            const drilldownDataPromises = [...peerIDs, selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/expenseDrilldown/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });

            const drilldownResults = await Promise.all(drilldownDataPromises);
            const drilldownDataJSON = Object.fromEntries(drilldownResults.filter(([_, data]) => data !== null));

            const drilldownDataArray = Object.entries(drilldownDataJSON).flatMap(([key, values]) => 
                Object.entries(values).map(([YEAR, details]) => ({
                  DISTRICT_COUNTY_CODE: key,
                  YEAR,
                  ...details
                }))
              );

            for(let i = 0; i<drilldownDataArray.length; i++){
                drilldownDataArray[i]['DISTRICT_NAME'] = drilldownDataArray[i]['Admin Expense']['DISTRICT_NAME'];
            }

            const drilldownData = expandArray(drilldownDataArray);
    
            // Update the state with the new dashboard data
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                data: {
                    ...prev.data,
                    drilldownData
                }
            }));
        } catch (error) {
            console.error('Error fetching dashboard data for peer group:', error);
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                error: error.message
            }));
        }
    };

    const handleBoardViewChange = (event) => {
        setAppState(prev => ({
            ...prev,
            data: { ...prev.data, boardView: event.target.value === 'true' }
        }));
    };

    const setActiveComponent = (component) => {
        setAppState(prev => ({
            ...prev,
            data: { ...prev.data, activeComponent: component }
        }));
    };

    const updatePeerGroupOptions = async (newOptions) => {
        // Update the state with the new peer group options
        setAppState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                peerGroupOptions: newOptions
            }
        }));

        // Re-fetch data after updating peer group options
        await initializeAppData(); // You can pass the current selected district if needed
    };

    // Render loading state or error
    if (appState.isLoading) {
        return <LoadingPage/>;
    }

    if (appState.error) {
        return <div className="app-error">Error: {appState.error}</div>;
    }

    // Render component based on active selection
    const renderComponent = () => {
        switch (appState.data.activeComponent) {
            case 'Overview':
                return <Dashboard selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                  boardView={appState.data.boardView} 
                                  dashboardData={appState.data.dashboardData}/>;
            case 'Budget':
                return <BudgetDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                   boardView={appState.data.boardView} 
                                   dashboardData={appState.data.dashboardData}
                                   drilldownData={appState.data.drilldownData}/>;
            case 'Faculty':
                return <FacultyDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                    boardView={appState.data.boardView} 
                                    dashboardData={appState.data.dashboardData}/>;
            case 'Students':
                return <StudentsDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                     boardView={appState.data.boardView} 
                                     dashboardData={appState.data.dashboardData}/>;
            case 'Academics':
                return <AcademicsDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                      boardView={appState.data.boardView} 
                                      dashboardData={appState.data.dashboardData}/>;
            case 'Revenue':
                return <RevenueDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                    boardView={appState.data.boardView} 
                                    dashboardData={appState.data.dashboardData}/>;
            case 'Debt':
                return <DebtDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                 boardView={appState.data.boardView} 
                                 dashboardData={appState.data.dashboardData}/>;
            case 'Salary':
            return <SalarySchedule selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                boardView={appState.data.boardView} 
                                dashboardData={appState.data.dashboardData}/>;
            case 'Monthly':
            return <MonthlyReport selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                boardView={appState.data.boardView} 
                                dashboardData={appState.data.dashboardData}
                                currentBudget={appState.data.currentBudget}/>;
            case 'Transport':
            return <Transportation selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                boardView={appState.data.boardView} 
                                dashboardData={appState.data.dashboardData}
                                currentBudget={appState.data.currentBudget}/>;
            case 'Peers':
                return <PeerSelection selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                      updatePeerGroupOptions={updatePeerGroupOptions}/>;
            case 'Forecast':
                return <ForecastDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                     boardView={appState.data.boardView} 
                                     dashboardData={appState.data.dashboardData}
                                     drilldownData={appState.data.drilldownData}/>;
            default:
                return <Dashboard selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                  boardView={appState.data.boardView} 
                                  dashboardData={appState.data.dashboardData}/>;
        }
    };

    // Main render
    return (
       <>
            <AuthWrapper onLogin={handleLogin} userFound={userFound} isFirstLogin={isFirstLogin}>
              
            </AuthWrapper>

      <div className="app">
      {userFound && (
        <>
        <Header 
            selectedDistrict={appState.data.selectedDistrict}
            selectedPeerGroup={appState.data.selectedPeerGroup}
            peerGroupOptions={appState.data.peerGroupOptions}
            boardView={appState.data.boardView}
            superAdmin={superAdmin}
            uniqueDistrictNames={uniqueDistrictNames.sort((a, b) => a.localeCompare(b))}
            handleDistrictChange={handleDistrictChange}
            handlePeerGroupChange={handlePeerGroupChange}
            handleBoardViewChange={handleBoardViewChange}
            />
            <Sidebar 
            selectedDistrict={appState.data.selectedDistrict}
            setActiveComponent={setActiveComponent}
            activeComponent={appState.data.activeComponent}
            boardView={appState.data.boardView}
            superAdmin={superAdmin}
            />
            
            {/* Render the active component */}
            {renderComponent()}
            {!appState.data.boardView && ( // Only render ChatBot when boardView is false
                <ChatBot 
                    selectedSchool={appState.data.selectedDistrict}
                    drilldownData={appState.data.drilldownData}
                />
            )}
            </>
        )}

        </div>
        </>
            
    )
        
    
}

export default App;
