import React, { useState } from 'react';
import ForecastTable from './ForecastTable';
import '../styles/ForecastDash.css';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title,annotationPlugin);

const ForecastDash = ({ selectedDistrictInfo, boardView, dashboardData, drilldownData }) => {
    const [expenseData, setExpenseData] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [surplusDeficitData, setSurplusDeficitData] = useState([]);
    const [isGraphVisible, setIsGraphVisible] = useState(true);

    const maxYear = Math.max(...Object.keys(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));
    const maxYearM1 = maxYear - 1;

    function arraysEqual(arr1, arr2) {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
    }

    const updateGraphData = (newData) => {
        let total_revenue = [];
        let total_expense = [];
        let surplus_deficit = [];

        newData.forEach(year => {
            const revenue = year.find(item => item.LABEL === 'Revenue')?.TOTAL_ALL_FUNDS || 0;
            const instruction = year.find(item => item.LABEL === 'Total Instruction Expense')?.TOTAL_ALL_FUNDS || 0;
            const nonInstruction = year.find(item => item.LABEL === 'Total Non-Instruction Expense')?.TOTAL_ALL_FUNDS || 0;
            const support = year.find(item => item.LABEL === 'Total Support Expense')?.TOTAL_ALL_FUNDS || 0;
            const expense = instruction + nonInstruction + support;

            total_revenue.push(revenue/1000000);
            total_expense.push(expense/1000000);
            surplus_deficit.push((revenue - expense)/1000000);
        });

        if (!arraysEqual(expenseData, total_expense)) setExpenseData(total_expense);
        if (!arraysEqual(revenueData, total_revenue)) setRevenueData(total_revenue);
        if (!arraysEqual(surplusDeficitData, surplus_deficit)) setSurplusDeficitData(surplus_deficit);
    };

    function calculateFundBalance() {
        const maxYearM1_endingBalance = dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYearM1].ENDING_BALANCE/1000000;
        const maxYear_endingBalance = dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].ENDING_BALANCE/1000000;
        const maxYearP1_endingBalance = surplusDeficitData[2] + maxYear_endingBalance;
        const maxYearP2_endingBalance = surplusDeficitData[3] + maxYearP1_endingBalance;
        const maxYearP3_endingBalance = surplusDeficitData[4] + maxYearP2_endingBalance;

        const endingBalance = [maxYearM1_endingBalance, maxYear_endingBalance, maxYearP1_endingBalance, maxYearP2_endingBalance, maxYearP3_endingBalance];

        var fund_balance = []
        for(let i=0; i<surplusDeficitData.length; i++){
            fund_balance.push((revenueData[i]+endingBalance[i]-expenseData[i])/expenseData[i])
        }

        return fund_balance
    }

    const fundBalance = calculateFundBalance();
    

    const fundBalanceChartData = {
        labels: ['2022', '2023', '2024', '2025', '2026'],
        datasets: [
            {
                label: 'Fund Balance',
                data: fundBalance,
                fill: false,
                tension: 0.1,
                borderColor: 'blue',
                backgroundColor: 'blue',
            }
        ]
    };

    const fundBalanceOptions = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'Fund Balance',
                font: {
                    size: 30,
                },
            },
            annotation: {
                annotations: {
                    forecastLine: {
                        type: 'line',
                        xMin: '2023',
                        xMax: '2023',
                        borderColor: 'rgba(0, 0, 0, 0.7)',
                        borderWidth: 2,
                        borderDash: [5, 5],
                        label: {
                            content: 'Forecast Begins',
                            enabled: true,
                            position: 'end',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            color: '#fff',
                        },
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Fiscal Year',
                    font: {
                        size: 22,
                    },
                },
                ticks: {
                    font: {
                        size: 20,
                    },
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Percent (%)',
                    font: {
                        size: 22,
                    },
                },
                ticks: {
                    font: {
                        size: 20,
                    },
                },
            },
        },
    };

    const dollarsChartData = {
        labels: ['2022', '2023', '2024', '2025', '2026'],
        datasets: [
            {
                label: 'Expenses',
                data: expenseData,
                fill: false,
                tension: 0.1,
                borderColor: 'red',
                backgroundColor: 'red',
            },
            {
                label: 'Revenue',
                data: revenueData,
                fill: false,
                tension: 0.1,
                borderColor: 'blue',
                backgroundColor: 'blue',
            },
            {
                label: 'Surplus/Deficit',
                data: surplusDeficitData,
                fill: false,
                tension: 0.1,
                borderColor: 'green',
                backgroundColor: 'green',
            }
        ]
    };

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'Revenue/Expenses',
                font: {
                    size: 30,
                },
            },
            annotation: {
                annotations: {
                    forecastLine: {
                        type: 'line',
                        xMin: '2023',
                        xMax: '2023',
                        borderColor: 'rgba(0, 0, 0, 0.7)',
                        borderWidth: 2,
                        borderDash: [5, 5],
                        label: {
                            content: 'Forecast Begins',
                            enabled: true,
                            position: 'end',
                            backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            color: '#fff',
                        },
                    },
                },
            },
        },
        scales: {
            x: {
                title: {
                    display: false,
                    text: 'Fiscal Year',
                    font: {
                        size: 22,
                    },
                },
                ticks: {
                    font: {
                        size: 20,
                    },
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Dollars (MM$)',
                    font: {
                        size: 22,
                    },
                },
                ticks: {
                    font: {
                        size: 20,
                    },
                },
            },
        },
    };

    return (
        <div className='forecast-dash-container'>
            <div 
                className='toggle-arrow' 
                onClick={() => setIsGraphVisible(!isGraphVisible)} 
            >
                {isGraphVisible ? '▼ Hide Graphs' : '► Show Graphs'}
            </div>
            
            
            {isGraphVisible && (
                <div className='graph-container'>
                    <div className='forecast-graph'>
                        <Line data={dollarsChartData} options={options} />
                    </div>
                    <div className='fund-balance-graph'>
                        <Line data={fundBalanceChartData} options={fundBalanceOptions} />
                    </div>
                </div>
            )}
            
            <div className="forecast-table">
                <ForecastTable 
                    drilldownData={drilldownData}
                    year={"2023"}
                    showGraph={false}
                    peerSchools={selectedDistrictInfo['peerGroupOptions']['names']}
                    selectedSchool={selectedDistrictInfo['DISTRICT_NAME']}
                    updateGraphData={updateGraphData}
                />
            </div>
            <div>
                <p>*Still being developed to include fund specific expenses to calculate fund balance</p>
            </div>
        </div>
    );
};

export default ForecastDash;
