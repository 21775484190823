import React from 'react';
import '../styles/Table.css';

const Table = ({ data, selectedSchoolInfo }) => {
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  function sortColumns(arr, selectedElement) {
    // Move "Year" to the front
    const yearIndex = arr.indexOf('Year');
    if (yearIndex > -1) {
        arr.splice(yearIndex, 1);
    }

    // Move the selected element to the second position
    const selectedIndex = arr.indexOf(selectedElement);
    if (selectedIndex > -1) {
        arr.splice(selectedIndex, 1);
    }

    // Create the sorted array
    return ['Year', selectedElement, ...arr];
}

  // Extract column headers from the first row's keys
  const columns = sortColumns(Object.keys(data[0]), selectedSchoolInfo.DISTRICT_NAME)

  return (
    <div
      onClick={(e) => e.stopPropagation()} // Stop the click event from bubbling up to the Card component
    >
      <table>
        <thead>
          <tr>
            {columns.map(column => (
              <th key={column}>{column}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map(column => (
                <td key={column}>{row[column] !== null ? row[column] : "N/A"}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
