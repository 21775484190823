import React, { useState } from 'react';
import Card from './Card';
import '../styles/Dashboard.css';

const Dashboard = ({ selectedDistrictInfo, boardView, dashboardData }) => {
    const [schoolData, setSchoolData] = useState({});

    const maxYear = Math.max(...Object.keys(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));

    // Helper functions
    const formatPrecision = (num, precision) => {
        return num ? parseFloat(num).toFixed(precision) : 'N/A';
    };

    const formatDollars = (num) => {
        return `$${Math.round(num).toLocaleString()}`;
    }; 

    const formatMillions = (num) => {
        return (num / 1000000).toFixed(2);
    };

    const calcYoYchange = (num_old, num_new) => {
        return (100 * (num_new - num_old) / num_old).toFixed(0);
    };

    const getArrowDirection = (num) => {
        if (num > 0) return "up";
        if (num < 0) return "down";
        return "flat";
    };

    function calculateAverage(array) {
        if (array.length === 0) {
          return 0;
        }
      
        const sum = array.reduce((acc, num) => acc + num, 0);
        return sum / array.length;
      }

    selectedDistrictInfo['peer_BUDGET_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['BUDGET']))
    selectedDistrictInfo['peer_AV_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['ASSESSED_VALUE']))
    selectedDistrictInfo['peer_FB_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['FUND_BALANCE']))
    selectedDistrictInfo['peer_LEVY_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['LEVY']))
    selectedDistrictInfo['peer_DEBT_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['TOTAL_DEBT']))
    selectedDistrictInfo['peer_CERTSALS_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['BUDGET_PERCENT_CERTIFICATED_SALARIES']))

    return (
        <div className="dashboard">
            <div className="title">Overview</div>

            <div className="dashboard-cards">
                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="Total Budget"
                    value={`$${formatMillions(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET)}m` || "$0"}
                    YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET,
                                       dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET) + '% YoY' || "0% YoY"}
                    smallText={`Peers: $${formatMillions(selectedDistrictInfo.peer_BUDGET_avg) + 'm' || "$0"}`}
                    bgColor="#88b0d8"
                    arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET,
                                                                       dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET))}`}
                    definition="The total budget is the amount of money the school district plans to spend in a given year. It includes all the funds needed to run the schools, such as paying for teachers, buying supplies, maintaining buildings, and running programs."
                    graphMetric="BUDGET"
                    yAxisTitle='Dollars ($)'
                    boardView={boardView}
                    graphData={dashboardData}
                />

                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="Assessed Value"
                    value={`${formatDollars(formatMillions(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].ASSESSED_VALUE))}m` || "$0"}
                    YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].ASSESSED_VALUE,
                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].ASSESSED_VALUE) + '% YoY' || "0% YoY"}
                    smallText={`Peers: $${formatMillions(selectedDistrictInfo.peer_AV_avg) + 'm' || "$0"}`}
                    bgColor="#d8abc1"
                    arrowDirection={`${getArrowDirection(schoolData?.AV_YoY_change)}`}
                    definition="The assessed value is the total value of all taxable property (like homes, businesses, and land) within the school district."
                    graphMetric="ASSESSED_VALUE"
                    yAxisTitle='Dollars ($)'
                    boardView={boardView}
                    graphData={dashboardData}
                />

                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="Operating Fund Balance"
                    value={dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].FUND_BALANCE + '%' || "$0"}
                    YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].FUND_BALANCE,
                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].FUND_BALANCE) + '% YoY' || "0% YoY"}
                    smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_FB_avg,2) + '%' || "$0"}`}
                    bgColor="#87b6a7"
                    arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].FUND_BALANCE,
                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].FUND_BALANCE))}`}
                    definition="The operating fund balance represents the percentage of the next school year that the district could cover with the money it currently has."
                    graphMetric="FUND_BALANCE"
                    yAxisTitle='Percent (%)'
                    boardView={boardView}
                    graphData={dashboardData}
                />

                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="Levy"
                    value={`$${formatPrecision(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].LEVY, 4)}` || "$0"}
                    YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].LEVY,
                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].LEVY) + '% YoY' || "0% YoY"}
                    smallText={`Peers: $${formatPrecision(selectedDistrictInfo.peer_LEVY_avg,4) || "$0"}`}
                    bgColor="#f79f79"
                    arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].LEVY,
                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].LEVY))}`}
                    definition="A levy is the amount of money the school district collects from local property taxes to help pay for its budget."
                    graphMetric="LEVY"
                    yAxisTitle='Dollars ($)'
                    boardView={boardView}
                    graphData={dashboardData}
                />

                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="Debt"
                    value={`$${formatMillions(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].TOTAL_DEBT)}m`}
                    YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].TOTAL_DEBT,
                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].TOTAL_DEBT) + '% YoY' || "0% YoY"}
                    smallText={`Peers: $${formatMillions(selectedDistrictInfo.peer_DEBT_avg) + 'm' || "$0"}`}
                    bgColor="#f7d08a"
                    arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].TOTAL_DEBT,
                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].TOTAL_DEBT))}`}
                    definition="Debt is the money the school district owes, typically from borrowing to pay for large projects like building new schools."
                    graphMetric="TOTAL_DEBT"
                    yAxisTitle='Dollars ($)'
                    boardView={boardView}
                    graphData={dashboardData}
                />

                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="Certified Salaries"
                    value={dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_CERTIFICATED_SALARIES + '%' || "$0"}
                    YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_CERTIFICATED_SALARIES,
                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_CERTIFICATED_SALARIES) + '% YoY' || "0% YoY"}
                    smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_CERTSALS_avg,2) + '%' || "$0"}`}
                    bgColor="#eee"
                    arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_CERTIFICATED_SALARIES,
                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_CERTIFICATED_SALARIES))}`}
                    definition="This percentage shows how much of the school district's budget is used to pay certified staff salaries, like teachers and administrators."
                    graphMetric="BUDGET_PERCENT_CERTIFICATED_SALARIES"
                    yAxisTitle='Percent (%)'
                    boardView={boardView}
                    graphData={dashboardData}
                />
            </div>
        </div>
    );
};

export default Dashboard;
