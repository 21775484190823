import React, { useState, useEffect } from 'react';
import Card from './Card';
import '../styles/Dashboard.css';

const DebtDash = ({ selectedDistrictInfo, boardView, dashboardData }) => {
    const [schoolData, setSchoolData] = useState({});

    const maxYear = Math.max(...Object.keys(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));

    // Helper functions
    const formatPrecision = (num, precision) => {
        return num ? parseFloat(num).toFixed(precision) : 'N/A';
    };

    const formatDollars = (num) => {
        return `$${Math.round(num).toLocaleString()}`;
    };

    function calculateAverage(array) {
        if (array.length === 0) {
          return 0;
        }
      
        const sum = array.reduce((acc, num) => acc + num, 0);
        return sum / array.length;
    }

    const calcYoYchange = (num_old, num_new) => {
        return (100 * (num_new - num_old) / num_old).toFixed(0);
    };

    const getArrowDirection = (num) => {
        if (num > 0) return "up";
        if (num < 0) return "down";
        return "flat";
    };

    selectedDistrictInfo['peer_DEBTSERVICE_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['DEBT_SERVICE_ADJ_RATE']));
    selectedDistrictInfo['peer_CAPPROJECTS_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['CAP_PROJECT_ADJ_RATE']));
    selectedDistrictInfo['peer_DEBTINTEREST_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['DEBT_INTEREST_PAID']));
    selectedDistrictInfo['peer_DEBTPRINCIPAL_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['DEBT_PRINCIPAL_PAID']));

    return (
        <div className="dashboard">
            <div className="title">Overview</div>

            <div className="dashboard-cards">
            <Card
                defaultSchool={selectedDistrictInfo}
                title="Debt Service Levy"
                value={`$${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].DEBT_SERVICE_ADJ_RATE}` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].DEBT_SERVICE_ADJ_RATE,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].DEBT_SERVICE_ADJ_RATE) + '% YoY' || '0% YoY'}
                smallText={`Peers: $${formatPrecision(selectedDistrictInfo.peer_DEBTSERVICE_avg,1) || "0"}`}
                bgColor="#88b0d8"
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].DEBT_SERVICE_ADJ_RATE,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].DEBT_SERVICE_ADJ_RATE))}`}
                definition="The tax levy specifically dedicated to paying off the district's debt obligations, including bonds issued for capital projects."
                graphMetric="DEBT_SERVICE_ADJ_RATE"
                yAxisTitle='Dollars ($)'
                boardView={boardView}
                graphData={dashboardData}
            />
            <Card
                defaultSchool={selectedDistrictInfo}
                title="Capital Projects Levy"
                value={`$${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].CAP_PROJECT_ADJ_RATE}` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].CAP_PROJECT_ADJ_RATE,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].CAP_PROJECT_ADJ_RATE) + '% YoY' || '0% YoY'}
                smallText={`Peers: $${formatPrecision(selectedDistrictInfo.peer_CAPPROJECTS_avg,1) || "0"}`}
                bgColor="#d8abc1"
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].CAP_PROJECT_ADJ_RATE,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].CAP_PROJECT_ADJ_RATE))}`}
                definition="A tax levy raised to fund capital projects such as construction, renovation, and major equipment purchases."
                graphMetric="CAP_PROJECT_ADJ_RATE"
                yAxisTitle='Dollars ($)'
                boardView={boardView}
                graphData={dashboardData}
            />
            <Card
                defaultSchool={selectedDistrictInfo}
                title="Debt Interest Paid"
                value={`${formatDollars(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].DEBT_INTEREST_PAID)}` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].DEBT_INTEREST_PAID,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].DEBT_INTEREST_PAID) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${formatDollars(selectedDistrictInfo.peer_DEBTINTEREST_avg) || "0"}`}
                bgColor="#87b6a7"
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].DEBT_INTEREST_PAID,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].DEBT_INTEREST_PAID))}`}
                definition="The amount of money paid by the district in interest on its outstanding debt obligations during a fiscal year."
                graphMetric="DEBT_INTEREST_PAID"
                yAxisTitle='Dollars ($)'
                boardView={boardView}
                graphData={dashboardData}
            />
            <Card
                defaultSchool={selectedDistrictInfo}
                title="Debt Principal Paid"
                value={`${formatDollars(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].DEBT_PRINCIPAL_PAID)}` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].DEBT_PRINCIPAL_PAID,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].DEBT_PRINCIPAL_PAID) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${formatDollars(selectedDistrictInfo.peer_DEBTPRINCIPAL_avg) || "0"}`}
                bgColor="#f79f79"
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].DEBT_PRINCIPAL_PAID,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].DEBT_PRINCIPAL_PAID))}`}
                definition="The amount of money the district has paid toward reducing the principal balance of its outstanding debt during a fiscal year."
                graphMetric="DEBT_PRINCIPAL_PAID"
                yAxisTitle='Dollars ($)'
                boardView={boardView}
                graphData={dashboardData}
            />
            </div>
        </div>
    );
};

export default DebtDash;
