import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import '../styles/LineGraph.css';

Chart.register(...registerables);

const colors = [
    '#495867', // Deep Gray-Blue
    '#f79f79', // Soft Coral
    '#f7d08a', // Warm Sand
    '#87b6a7', // Muted Teal
    '#d8abc1', // Dusty Pink
    '#88b0d8', // Light Blue
    '#b0c7a5', // Sage Green
    '#f0a1a8', // Light Coral
    '#b9a2d8', // Soft Lavender
    '#a5c4c9'  // Cool Light Blue
  ];

const formatYAxisLabel = (label) => {
    return label
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

const LineGraph = ({ data, metric, selectedDistricts, showLegend, yAxisLabel = '', graphTitle = '' }) => {
    const schools = [...new Set(data.map(item => item.DISTRICT_NAME))];

    // Sort alphabetically, keeping the initially selected school at the front
    const initialSelectedSchool = selectedDistricts.length > 0 ? selectedDistricts[0] : schools[0];
    const orderedSchools = [
        initialSelectedSchool, 
        ...schools.filter(school => school !== initialSelectedSchool).sort()
    ];

    // Set the default selected schools to the initial selected school only
    const [selectedSchools, setSelectedSchools] = useState([initialSelectedSchool]);

    const handleCheckboxChange = (school, e) => {

        setSelectedSchools(prevSelected => {
            const isSelected = prevSelected.includes(school);
            return isSelected
                ? prevSelected.filter(s => s !== school) // Remove if unchecked
                : [...prevSelected, school]; // Add to selected list if checked
        });
    };

    const years = [...new Set(data.map(item => item.YEAR))].sort();

    // Assign colors based on orderedSchools to maintain consistent color order in legend
    const colorMap = orderedSchools.reduce((acc, school, index) => {
        acc[school] = colors[index % colors.length];
        return acc;
    }, {});

    // Prepare datasets with consistent colors and ordered by orderedSchools
    const datasets = orderedSchools
        .filter(school => selectedSchools.includes(school)) // Only include selected schools
        .map((school) => {
            const metricData = years.map(year => {
                const item = data.find(d => d.DISTRICT_NAME === school && d.YEAR === year);
                return item ? parseFloat(item[metric]) : 0;
            });

            return {
                label: school,
                data: metricData,
                borderColor: colorMap[school], // Consistent color based on orderedSchools
                lineWidth: 3,
                fill: false,
                tension: 0.2,
                pointStyle: 'circle',
                pointRadius: 4,
                pointBackgroundColor: colorMap[school]
            };
        });

    const chartData = {
        labels: years,
        datasets: datasets,
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: showLegend,
                position: 'right',
                labels: {
                    usePointStyle: true,
                    font: { size: 16 },
                    boxWidth: 20,
                    boxHeight: 20,
                    padding: 20,
                },
            },
            title: {
                display: true,
                text: graphTitle || formatYAxisLabel(metric),
                font: { size: 28 },
                padding: { top: 20, bottom: 30 },
            },
        },
        scales: {
            x: {
                ticks: { font: { size: 28 } },
                title: { display: true, font: { size: 28 }, text: 'Year' },
            },
            y: {
                beginAtZero: true,
                ticks: { font: { size: 28 } },
                title: { display: !!yAxisLabel, text: yAxisLabel || '', font: { size: 28 } },
            },
        },
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '100%', height: '100%' }}> {/* Fixed height for container */}
            <div className="school-selection">
                {orderedSchools.map(school => (
                    <label key={school} style={{ display: 'block', marginBottom: '8px' }}>
                        <input
                            type="checkbox"
                            checked={selectedSchools.includes(school)}
                            onChange={(e) => handleCheckboxChange(school, e)}
                        />
                        {school}
                    </label>
                ))}
            </div>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default LineGraph;
