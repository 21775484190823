import React, { useState, useEffect } from 'react';

const ForecastTable = ({ drilldownData, year, selectedSchool, updateGraphData }) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [multipliers, setMultipliers] = useState([]);
  const [editingCell, setEditingCell] = useState([]); // To store the ID of the currently edited cell
  const [inputValue, setInputValue] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [currentYearPlus1, setCurrentYearPlus1] = useState([]);
  const [currentYearPlus2, setCurrentYearPlus2] = useState([]);
  const [currentYearPlus3, setCurrentYearPlus3] = useState([]);

  const formatDollars = (num) => {
    return `$${Math.round(num).toLocaleString()}`;
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  };

  const handleCellClick = (id, value) => {
    setEditingCell(id);
    setInputValue(value); // Prepopulate the input with the current value
  };

  const handleInputBlur = () => {
    if (editingCell) {
      handleValueChange(editingCell, inputValue); // Trigger a callback to save the new value
    }
    setEditingCell(null); // Exit edit mode
  };
  
  function weightedAverage(values, weights) {
    // Check if arrays are the same length and not empty
    if (values.length !== weights.length || values.length === 0) {
      throw new Error("Values and weights must be the same length and not empty.");
    }
  
    // Calculate the sum of each value times its weight
    const weightedSum = values.reduce((sum, value, index) => sum + value * weights[index], 0);
  
    // Calculate the sum of the weights
    const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);
  
    // Divide the weighted sum by the total weight
    return totalWeight === 0 ? 0 : weightedSum / totalWeight;
  }

  function findChildren(label) {
    //const uniqueLabels = new Set(); 
    const objectArray = currentYear.filter(item => item.PARENT === label) 
    return objectArray.map(item=>item.LABEL)
  }

  function findParent(label) {
    return currentYear.filter(item=>item.LABEL===label)[0].PARENT
  }

  function findChildrenItems(yearData, label) {
    //const uniqueLabels = new Set(); 
    const objectArray = yearData.filter(item => item.PARENT === label) 
    return objectArray
  }
  
  const calculateProjectedYear = (previousYear, year_idx) => {
    return multipliers.length > 0 
      ? previousYear.map(item => ({
          ...item,
          YEAR: Number(year) + year_idx + 1,
          TOTAL_ALL_FUNDS: item.TOTAL_ALL_FUNDS * (1 + multipliers[year_idx][item.LABEL] / 100),
        }))
      : [];
  };

  const handleValueChange = (id, val) => {
    const yearIndex = parseInt(id.slice(-1));
    const label = id.slice(0,-2);
    var lastYearValue = 999;
    var parentLabel = '';
    var parentLastYear = 0;
    var parentNewValue = 0;

    data[yearIndex]?.forEach(item => {
      if (item.LABEL === label) {
        item.TOTAL_ALL_FUNDS = val;
        parentLabel = item.PARENT;
      }
    });

    // if last year's value was zero, put null in percent change field
    if(multipliers[yearIndex-2]){
      data[yearIndex-1]?.forEach(item => {
        if (item.LABEL === label) {
          lastYearValue = item.TOTAL_ALL_FUNDS;
        }
      });
      if(parseInt(lastYearValue)===0){
        multipliers[yearIndex-2][label] = ''
      } else {
        multipliers[yearIndex-2][label] = 100*(val - lastYearValue)/lastYearValue;
      }
    }

    while(parentLabel!='Self'){
      // update parent value to equal sum(children)
      data[yearIndex]?.forEach(item => {
        parentNewValue = findChildrenItems(data[yearIndex],parentLabel).reduce((total, item) => total + parseFloat(item.TOTAL_ALL_FUNDS), 0);
        if (item.LABEL === parentLabel) {
          item.TOTAL_ALL_FUNDS = parentNewValue;
        }
      })

      // get last year's number for multiplier calculation
      data[yearIndex-1]?.forEach(item => {
        if (item.LABEL === parentLabel) {
          parentLastYear = item.TOTAL_ALL_FUNDS;
        }
      })

      // re-calculate parent's multiplier
      if (parentNewValue != 0) {
        multipliers[yearIndex-2][parentLabel] = 100*(parentNewValue - parentLastYear)/parentLastYear;
      }

      parentLabel = findParent(parentLabel);
    }

    // Propagate parent changes forward in time
    let currentYearPlus2Temp;
    let currentYearPlus3Temp;
    switch (yearIndex){
    case 2:
      currentYearPlus2Temp = calculateProjectedYear(data[yearIndex], yearIndex-1);
      currentYearPlus3Temp = calculateProjectedYear(currentYearPlus2Temp, yearIndex);
      break;
    case 3:
      currentYearPlus2Temp = data[yearIndex];
      currentYearPlus3Temp = calculateProjectedYear(currentYearPlus2Temp, yearIndex-1);
      break;
    case 4:
      currentYearPlus2Temp = data[yearIndex-1];
      currentYearPlus3Temp = data[yearIndex];
    }
    setCurrentYearPlus2(currentYearPlus2Temp);
    setCurrentYearPlus3(currentYearPlus3Temp);

  };

  const handleMultiplierChange = (index, label, value) => {

    setMultipliers((prev) => {
      const updatedMultipliers = [...prev]; // Create a copy of the array
      const targetObject = updatedMultipliers[index];
      
      targetObject[label] = parseFloat(value);
      const childLabels = findChildren(label);

      childLabels.map(child_label => {
        findChildren(child_label).map(item => childLabels.push(item))
      })

      // When parent changes, change the children to be the same
      childLabels.map(child_label => targetObject[child_label] = parseFloat(value))

      // When child changes, calculate what the parent should be
      const parentLabel = data[index+1]?.filter(item => item.LABEL === label)[0]['PARENT'];
      if(parentLabel!='Self'){
        const childItems = findChildrenItems(data[index+1], parentLabel);

        var weights = [];
        var values = [];

        childItems.map(item=>{
          values.push(parseFloat(targetObject[item.LABEL]));
          weights.push(parseFloat(item.TOTAL_ALL_FUNDS));
        });


        targetObject[parentLabel] =  weightedAverage(values, weights);
      }

      updatedMultipliers[index] = targetObject;

      return updatedMultipliers; // Return the updated array
    });

    // Re-calculate future values
    let currentYearP1Temp;
    let currentYearP2Temp;
    let currentYearP3Temp;
    switch (index){
      case 0:
        currentYearP1Temp = calculateProjectedYear(data[index+1], index);
        currentYearP2Temp = calculateProjectedYear(currentYearP1Temp, index+1);
        currentYearP3Temp = calculateProjectedYear(currentYearP2Temp, index+2);
        break;
      case 1:
        currentYearP1Temp = data[index+1];
        currentYearP2Temp = calculateProjectedYear(currentYearP1Temp, index);
        currentYearP3Temp = calculateProjectedYear(currentYearP2Temp, index+1);
        break;
      case 2:
        currentYearP1Temp = data[index];
        currentYearP2Temp = calculateProjectedYear(currentYearP1Temp, index-1);
        currentYearP3Temp = calculateProjectedYear(currentYearP2Temp, index);
        break;
    }

    setCurrentYearPlus1(currentYearP1Temp);
    setCurrentYearPlus2(currentYearP2Temp);
    setCurrentYearPlus3(currentYearP3Temp);

    /*
    const currentYearPlus1Temp = calculateProjectedYear(data[index+1], index);
    const currentYearPlus2Temp = calculateProjectedYear(currentYearPlus1Temp, index+1);
    const currentYearPlus3Temp = calculateProjectedYear(currentYearPlus2Temp, index+2);

    setCurrentYearPlus1(currentYearPlus1Temp);
    setCurrentYearPlus2(currentYearPlus2Temp);
    setCurrentYearPlus3(currentYearPlus3Temp);
    */

  };

  /********** INITIALIZE DATA  *************/

  const currentYear = drilldownData.filter(item => (item.DISTRICT_NAME === selectedSchool) && (item.YEAR === year));
  const currentYearMinus1 = drilldownData.filter(item => (item.DISTRICT_NAME === selectedSchool) && (Number(item.YEAR) === Number(year) - 1));

  useEffect(() => {
    if (multipliers.length === 0) {
      const multiplierArray = Array(3).fill(null).map(() => {
        const initialMultipliers = {};
        currentYear.forEach((item) => {
          initialMultipliers[item.LABEL] = 4;
        });
        return initialMultipliers;
      });
      setMultipliers(multiplierArray);
    }
  
    if(initialLoad){
      setCurrentYearPlus1(calculateProjectedYear(currentYear, 0));
      setCurrentYearPlus2(calculateProjectedYear(currentYearPlus1, 1));
      setCurrentYearPlus3(calculateProjectedYear(currentYearPlus2, 2));
    }
    // if all data is loaded, set initial load to false
    if((currentYearPlus1.length!=0)&(currentYearPlus2.length!=0)&(currentYearPlus3.length!=0)){
      setInitialLoad(false);
    }

  }, [currentYear, multipliers, initialLoad]);

  const data = [currentYearMinus1, currentYear, currentYearPlus1, currentYearPlus2, currentYearPlus3];

  useEffect(() => {updateGraphData(data);});

  function displayColumn(columnData, idx, item) {
    const columnItem = columnData.find(
      (entry) => entry.LABEL === item.LABEL && entry.PARENT === item.PARENT
    );
    const cellId = `${item.LABEL}-${idx}`; // Unique ID for each cell
    const isTerminalNode = findChildren(cellId.slice(0, -2)).length === 0;
    const isProjectedYear = idx > 1; // Only allow editing for future years
  
    return (
      <td style={{ textAlign: 'right', borderRight: idx === 1 ? '1.5px dashed black' : 'none' }} onClick={() => handleCellClick(cellId, columnItem?.TOTAL_ALL_FUNDS)}>
        {(isTerminalNode && isProjectedYear && editingCell === cellId) ? (
          <input
            type="text"
            value={inputValue || parseFloat(columnItem.TOTAL_ALL_FUNDS).toFixed(2)}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            autoFocus
            style={{ width: '100%', textAlign: 'right' }}
          />
        ) : (
          <span onClick={() => handleCellClick(cellId, columnItem?.TOTAL_ALL_FUNDS)}>
            {columnItem ? formatDollars(columnItem.TOTAL_ALL_FUNDS) : '-'}
          </span>
        )}
      </td>
    );
  }
  
  

  function multiplierColumn(multipliers, item, idx) { 
    return (
      <td>
        <div style={{ position: 'relative', display: 'inline-block' }}>
          <input
            type="number"
            value={multipliers[idx] ? multipliers[idx][item.LABEL] : 1.02}
            onChange={(e) => handleMultiplierChange(idx, item.LABEL, e.target.value)}
            onClick={(e) => e.stopPropagation()} // Prevent row click from triggering
            style={{
              width: '44px',
              paddingRight: '15px',
              border: '2px #ccc',
              borderRadius: '4px',
              fontSize: '15px',
            }}
            step = "0.25"
          />
          <span style={{
              position: 'absolute',
              right: '8px',
              top: '50%',
              transform: 'translateY(-50%)',
              pointerEvents: 'none',
              color: '#6b7280'
            }}>%</span>
        </div>
      </td>
    );
  }

  const toggleExpand = (label) => {
    setExpandedRows((prev) => ({ ...prev, [label]: !prev[label] }));
  };

  const renderRows = (parent, level = 0) => {
    return data[0]
      .filter((item) => item.PARENT === parent)
      .map((item) => {
        const children = data[0].filter(entry => entry.PARENT === item.LABEL);
        const isExpandable = children.length > 0;
        const isExpanded = expandedRows[item.LABEL];
  
        return (
          <React.Fragment key={`${parent}-${item.LABEL}`}>
            <tr
              style={{
                cursor: 'pointer',
                backgroundColor: level % 2 === 0 ? '#f0f8ff' : '#e6f7ff'
              }}
            >
              <td
                style={{ paddingLeft: `${level * 20}px`, cursor: 'pointer' }}
                onClick={() => toggleExpand(item.LABEL)}
              >
                {isExpandable ? (
                  <span
                    style={{
                      display: 'inline-block',
                      transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
                      transition: 'transform 0.35s',
                      marginRight: '5px',
                      marginLeft: '5px'
                    }}
                  >
                    ▶
                  </span>
                ) : (
                  <span style={{ marginRight: '5px' }}>-</span>
                )}
                {item.LABEL}
              </td>
              {displayColumn(data[0], 0, item)}
              {displayColumn(data[1], 1, item)}
              {multiplierColumn(multipliers, item, 0)}
              {displayColumn(data[2], 2, item)}
              {multiplierColumn(multipliers, item, 1)}
              {displayColumn(data[3], 3, item)}
              {multiplierColumn(multipliers, item, 2)}
              {displayColumn(data[4], 4, item)}
            </tr>
            {isExpanded && renderRows(item.LABEL, level + 1)}
          </React.Fragment>
        );
      });
  };
  
  

  return (
    <table
        //border=".5 #ccc"
        style={{
          borderCollapse: 'collapse',
          width: '100%', // Make the table wider
          borderRadius: '10px',
          overflow: 'hidden',
          fontSize: '16px', // Enlarge table text for readability
          border: '10px black', // Ensure black border is visible
          //margin: '10px',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)', // Add shadow effect
          backgroundColor: '#eee',
          marginBottom: '5.5%',
          marginTop: '5%'
        }}
      >
      <thead>
        <tr>
          <th>Label</th>
          <th style={{ textAlign: 'right' }}>{2022}</th>
          <th style={{ textAlign: 'right', borderRight: '1.5px dashed black' }}>{2023}</th>
          <th>%</th>
          <th style={{ textAlign: 'right' }}>{2024}</th>
          <th>%</th>
          <th style={{ textAlign: 'right' }}>{2025}</th>
          <th>%</th>
          <th style={{ textAlign: 'right' }}>{2026}</th>
        </tr>
      </thead>
      <tbody>{renderRows('Self')}</tbody>
    </table>
  );
};

export default ForecastTable;
