import React, { useState } from 'react';
import LineGraph from './LineGraph';

const formatDollars = (num) => {
  return `$${Math.round(num).toLocaleString()}`;
};

const DrilldownTable = ({ data, year, peerSchools = [], selectedSchool, showGraphOption = true }) => {
  const [expandedLabels, setExpandedLabels] = useState([]);
  const [selectedGraphLabel, setSelectedGraphLabel] = useState(null);
  const [viewMode, setViewMode] = useState('graph');

  const filteredData = data.filter((row) => row.YEAR === year);

  // Group data by label and district
  const groupDataByLabelAndDistrict = (rows) => {
    return rows.reduce((acc, item) => {
      const { DISTRICT_NAME, LABEL, TOTAL_ALL_FUNDS } = item;
      if (!acc[LABEL]) {
        acc[LABEL] = {};
      }
      acc[LABEL][DISTRICT_NAME] = TOTAL_ALL_FUNDS;
      return acc;
    }, {});
    
  };
  


  // Handle row click for expanding/collapsing
  const handleRowClick = (label) => {
    setExpandedLabels((prevExpandedLabels) =>
      prevExpandedLabels.includes(label)
        ? prevExpandedLabels.filter((l) => l !== label)
        : [...prevExpandedLabels, label]
    );
  };

  // Determine if the label is the final child in drilldown
  const isFinalChild = (label) => {
    return filteredData.every((item) => item.PARENT !== label);
  };

  // Handle graph display toggle
  const handleShowGraphClick = (label) => {
    setSelectedGraphLabel((prevLabel) => (prevLabel === label ? null : label));
  };

  // Handle view mode toggle (graph or raw data)
  const toggleViewMode = () => {
    setViewMode(viewMode === 'graph' ? 'spreadsheet' : 'graph');
  };

  // Conditionally add peerSchools only if provided
  const districtNames = [selectedSchool].concat(peerSchools);

  // Render rows recursively for drill-down
  const renderRows = (parentLabel, level = 0) => {
    const currentRows = filteredData.filter((row) => row.PARENT === parentLabel);
  
    if (!currentRows.length) return null;

    const groupedCurrentRows = groupDataByLabelAndDistrict(currentRows);
    const backgroundColors = ['#e3f2fd', '#bbdefb', '#90caf9', '#64b5f6'];

    return Object.entries(groupedCurrentRows).map(([label, districtValues]) => {
      const backgroundColor = backgroundColors[level % backgroundColors.length];

      return (

        <React.Fragment key={label}>
          <tr
            onClick={() => handleRowClick(label)}
            style={{ cursor: 'pointer', backgroundColor, position: 'relative', height: '50px' }}
          >
            <td
              style={{
                paddingLeft: `${level * 20}px`,
                textAlign: 'left',
                fontSize: '18px',
                padding: '15px',
                whiteSpace: 'pre',
              }}
            >
              {`${'    '.repeat(level)}`}
              {isFinalChild(label) && (
                <span style={{ marginRight: '5px', fontSize: '24px', fontWeight: 'bold', color: 'black' }}>–</span>
              )}
              {!isFinalChild(label) && ( 
                <span
                  style={{
                    display: 'inline-block',
                    transform: expandedLabels.includes(label) ? 'rotate(90deg)' : 'rotate(0deg)',
                    transition: 'transform 0.25s ease-in-out',
                    marginRight: '5px',
                  }}
                >
                  ▶
                </span>
              )}
              {label}
              {showGraphOption && (
                <span
                  role="img"
                  aria-label="Show Graph"
                  style={{ cursor: 'pointer', marginLeft: '3px' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleShowGraphClick(label);
                  }}
                >
                  📈
                </span>
              )}
            </td>
            {districtNames.map((district) => (
              <td
                key={`${label}-${district}`}
                style={{
                  fontSize: '16px',
                  padding: '15px',
                  width: '150px',
                  textAlign: 'center',
                }}
              >
                <span style={districtValues[district] > 10000000000 ? { backgroundColor: '#ffcccc' } : {}}>
                  {formatDollars(districtValues[district] || 0)}
                </span>
              </td>
            ))}
          </tr>

          {selectedGraphLabel === label && (
            <tr>
              <td colSpan={districtNames.length + 1} style={{ padding: '20px', position: 'relative' }}>
                <div style={{ width: '100%', height: '500px', overflow: 'hidden', position: 'relative' }}>
                  {viewMode === 'graph' ? (
                    <div style={{ width: '100%', height: '90%' }}>
                      <LineGraph
                        data={getGraphData(label)}
                        metric="TOTAL_ALL_FUNDS"
                        selectedDistricts={districtNames}
                        yAxisLabel="Dollars ($)"
                        graphTitle={label}
                      />
                    </div>
                  ) : (
                    <div>
                      <table style={{ backgroundColor: '#eee', border: '10px solid black', margin: '10px auto' }}>
                        <thead>
                          <tr>
                            <th style={{ textAlign: 'center', padding: '10px' }}>Year</th>
                            {districtNames.map((district) => (
                              <th key={district} style={{ textAlign: 'center', padding: '10px' }}>{district}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {getRawData(label).map((row, index) => (
                            <tr key={index}>
                              <td style={{ textAlign: 'center', padding: '10px' }}>{row.YEAR}</td>
                              {districtNames.map((district) => (
                                <td key={district} style={{ textAlign: 'center', padding: '10px' }}>{formatDollars(row[district])}</td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <button onClick={toggleViewMode} style={{ position: 'absolute', bottom: '5px', right: '10px' }}>
                  {viewMode === 'graph' ? 'View Raw Data' : 'View Graph'}
                </button>
              </td>
            </tr>
          )}

          {expandedLabels.includes(label) && renderRows(label, level + 1)}
        </React.Fragment>
      );
    });
  };

  const getGraphData = (label) => {
    return data
      .filter((row) => row.LABEL === label)
      .map((row) => ({
        DISTRICT_NAME: row.DISTRICT_NAME,
        YEAR: row.YEAR,
        TOTAL_ALL_FUNDS: row.TOTAL_ALL_FUNDS,
      }));
  };

  const getRawData = (label) => {
    const years = [...new Set(data.map((row) => row.YEAR))].sort((a, b) => b - a);
    const rawData = years.map((year) => {
      const row = { YEAR: year };
      districtNames.forEach((district) => {
        const districtData = data.find(
          (d) => d.LABEL === label && d.YEAR === year && d.DISTRICT_NAME === district
        );
        row[district] = districtData ? districtData.TOTAL_ALL_FUNDS : 0;
      });
      return row;
    });
    return rawData;
  };

  return (
    <div>
      <table
        border="1"
        style={{
          borderCollapse: 'collapse',
          width: '118%',
          borderRadius: '10px',
          overflow: 'hidden',
          fontSize: '18px',
          border: '10px solid black',
          margin: '10px -60px',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
          backgroundColor: '#eee'
        }}
      >
        <thead>
          <tr>
            <th style={{ borderTopLeftRadius: '10px', padding: '15px', textAlign: 'center' }}>Label</th>
            {districtNames.map((district, idx) => (
              <th
                key={district}
                style={
                  idx === districtNames.length - 1
                    ? { borderTopRightRadius: '10px', padding: '15px' }
                    : { padding: '15px' }
                }
              >
                {district}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{renderRows('Self')}</tbody>
      </table>
    </div>
  );
};

export default DrilldownTable;
