import React, { useState, useEffect } from 'react';
import { database } from '../firebaseConfig';
import { ref, get, set } from "firebase/database";
import '../styles/Dashboard.css';

const balanceTheme = {
    backgroundColor: '#e7f4fc',
    inputColor: '#ffffff',
    textColor: '#333333'
};

const Transportation = ({ selectedDistrictInfo }) => {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    const [transportationData, setTransportationData] = useState([]);

    useEffect(() => {
        const loadTransportationData = async () => {
            const districtCode = selectedDistrictInfo['DISTRICT_COUNTY_CODE'];
            const dataRef = ref(database, `transportationRecords/${districtCode}`);
            
            try {
                const snapshot = await get(dataRef);
                if (snapshot.exists()) {
                    const savedData = snapshot.val();
                    setCurrentYear(savedData.currentYear || currentYear);
                    setTransportationData(savedData.transportationData || []);
                }
            } catch (error) {
                console.error("Error loading data:", error);
            }
        };
        
        loadTransportationData();
    }, [selectedDistrictInfo]);

    const addRow = () => {
        setTransportationData([...transportationData, {
            year: '',
            number: '',
            model: '',
            body: '',
            seats: '',
            license: '',
            typeRoute: '',
            mileage: '',
            comments: '',
            milesPerYear: ''
        }]);
    };

    const handleInputChange = (index, field, value) => {
        const updatedData = [...transportationData];
        updatedData[index][field] = value;

        if (field === 'year' || field === 'mileage') {
            const year = parseInt(updatedData[index].year) || 0;
            const mileage = parseFloat(updatedData[index].mileage.replace(/,/g, '')) || 0;
            if (year > 0 && mileage > 0 && currentYear > year) {
                updatedData[index].milesPerYear = (mileage / (currentYear - year)).toLocaleString('en-US', { maximumFractionDigits: 0 });
            } else {
                updatedData[index].milesPerYear = '';
            }
        }

        // Format Mileage with commas
        if (field === 'mileage') {
            updatedData[index].mileage = value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        setTransportationData(updatedData);
    };

    const handleSaveToFirebase = async () => {
        const districtCode = selectedDistrictInfo['DISTRICT_COUNTY_CODE'];
        const dataToSave = {
            currentYear,
            transportationData
        };

        try {
            await set(ref(database, `transportationRecords/${districtCode}`), dataToSave);
            alert("Transportation data saved successfully!");
        } catch (error) {
            console.error("Error saving data:", error);
            alert("Failed to save transportation data. Please try again.");
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <div style={{ backgroundColor: balanceTheme.backgroundColor, padding: '20px', borderRadius: '8px', width: '95%', margin: '0 auto' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: balanceTheme.textColor }}>
                    <label>Current Year:</label>
                    <input
                        type="number"
                        value={currentYear}
                        onChange={(e) => setCurrentYear(parseInt(e.target.value))}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', width: '80px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    />
                </div>

                <table className="transportation-table" style={{ width: '95%', borderCollapse: 'collapse', marginTop: '20px' }}>
                    <thead>
                        <tr>
                            <th>Year</th>
                            <th>Number</th>
                            <th>Model</th>
                            <th>Body</th>
                            <th>Number Seats/Realistic</th>
                            <th>License Number</th>
                            <th>Type Route</th>
                            <th>Mileage</th>
                            <th>Comments</th>
                            <th>Miles Per Year</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transportationData.map((row, index) => (
                            <tr key={index}>
                                <td><input type="number" value={row.year} onChange={(e) => handleInputChange(index, 'year', e.target.value)} style={{ backgroundColor: balanceTheme.inputColor, width: '80px', border: '1px solid #b3c7d6', borderRadius: '4px' }} /></td>
                                <td><input type="text" value={row.number} onChange={(e) => handleInputChange(index, 'number', e.target.value)} style={{ backgroundColor: balanceTheme.inputColor, width: '80px', border: '1px solid #b3c7d6', borderRadius: '4px' }} /></td>
                                <td><input type="text" value={row.model} onChange={(e) => handleInputChange(index, 'model', e.target.value)} style={{ backgroundColor: balanceTheme.inputColor, width: '80px', border: '1px solid #b3c7d6', borderRadius: '4px' }} /></td>
                                <td><input type="text" value={row.body} onChange={(e) => handleInputChange(index, 'body', e.target.value)} style={{ backgroundColor: balanceTheme.inputColor, width: '80px', border: '1px solid #b3c7d6', borderRadius: '4px' }} /></td>
                                <td><input type="text" value={row.seats} onChange={(e) => handleInputChange(index, 'seats', e.target.value)} style={{ backgroundColor: balanceTheme.inputColor, width: '80px', border: '1px solid #b3c7d6', borderRadius: '4px' }} /></td>
                                <td><input type="text" value={row.license} onChange={(e) => handleInputChange(index, 'license', e.target.value)} style={{ backgroundColor: balanceTheme.inputColor, width: '80px', border: '1px solid #b3c7d6', borderRadius: '4px' }} /></td>
                                <td><input type="text" value={row.typeRoute} onChange={(e) => handleInputChange(index, 'typeRoute', e.target.value)} style={{ backgroundColor: balanceTheme.inputColor, width: '80px', border: '1px solid #b3c7d6', borderRadius: '4px' }} /></td>
                                <td><input type="text" value={row.mileage} onChange={(e) => handleInputChange(index, 'mileage', e.target.value)} style={{ backgroundColor: balanceTheme.inputColor, width: '80px', border: '1px solid #b3c7d6', borderRadius: '4px' }} /></td>
                                <td><input type="text" value={row.comments} onChange={(e) => handleInputChange(index, 'comments', e.target.value)} style={{ backgroundColor: balanceTheme.inputColor, width: '100%', border: '1px solid #b3c7d6', borderRadius: '4px' }} /></td>
                                <td>{row.milesPerYear}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <button onClick={addRow} style={{ marginTop: '20px', padding: '10px', backgroundColor: '#007BFF', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Add Transportation Entry
                </button>

                <button onClick={handleSaveToFirebase} style={{ marginTop: '20px', padding: '10px', backgroundColor: '#28a745', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Save Transportation Data
                </button>
            </div>
        </div>
    );
};

export default Transportation;
