import React, { useState, useEffect, useRef } from 'react';
import '../styles/ChatBot.css';
import ReactMarkdown from 'react-markdown';

const ChatBot = ({selectedSchool, drilldownData}) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [spreadsheetData, setSpreadsheetData] = useState(null);
  const [initialPromptLoaded, setInitialPromptLoaded] = useState(false);
  const [isTyping, setIsTyping] = useState(false);  // New state for typing indicator
  const textareaRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setSpreadsheetData(drilldownData.filter(item=>(item.DISTRICT_NAME===selectedSchool)&(item.YEAR==='2023')))
  }, [selectedSchool, drilldownData]);
  

  useEffect(() => {
    if (spreadsheetData && !initialPromptLoaded) {
      const initialPrompt = `
        You are an experienced school CFO & Superintendent with a background in banking & public k12 education, you value efficiency, student performance, and making the most of tax-payer dollars. 
        The superintendent of a Missouri school is coming to you with questions about their school district budget. Your job is to respond to their questions based on your experience and pulling information from the spreadsheet to form your opinions. 
        Success is in developing a narrative of how trends in a school compare to peers that the superintendent can share with their school board.
        Don't speak frankly, be kind & gentle in how you communicate the informaiton, coming off as optimistic and realistic, your answers are clear but concise & to the point.
        If you're going to tell them to look deeper into something, do it for them if the data is available.
        A budget of too much excess or too much deficit can both be signs of sub-optimal budgeting. 
        When you analyze numbers in a school budget, make sure to review the numbers in comparison to other aspects of budget & performance and assess how they interact with and are informed by each other.
        Also, reference the following data from an Excel spreadsheet:
        ${JSON.stringify(spreadsheetData)}
      `;

      setMessages([{ role: 'system', content: initialPrompt }]);
      setInitialPromptLoaded(true);
    }
  }, [spreadsheetData, initialPromptLoaded]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  const handleSend = async () => {
    if (input.trim()) {
      setMessages([...messages, { role: 'user', content: input }]);
      setInput('');
      setIsTyping(true);  // Show typing indicator

      try {
        const response = await fetch('http://localhost:5001/api/chatbot', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ messages: [...messages, { role: 'user', content: input }] }),
        });
        const data = await response.json();
        setMessages(prev => [...prev, { role: 'assistant', content: data.reply }]);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsTyping(false);  // Hide typing indicator
      }
    }
  };

  return (
    <div className={`chatbot-container ${isOpen ? 'open' : 'closed'}`}>
      <div className="chatbot-header" onClick={toggleChatbot}>
        <span>{isOpen ? 'Ask Linda' : 'Ask Linda'}</span>
        <span className="chatbot-minimizer">{isOpen ? '-' : ''}</span>
      </div>
      {isOpen && (
        <div className="chatbot-messages">
          {messages.map((message, index) => (
            message.role !== 'system' && (
              <div key={index} className={`message ${message.role}`}>
                <ReactMarkdown>{message.content}</ReactMarkdown>
              </div>
            )
          ))}
          {isTyping && (
            <div className="typing-indicator">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          )}
          <textarea
            ref={textareaRef}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSend()}
            rows={1}
            className="input-textarea"
            placeholder="Type Here . . ."
          />
          <button onClick={handleSend}>Send</button>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
