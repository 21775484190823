import React, { useState, useEffect } from 'react';
import { database } from '../firebaseConfig';
import { ref, get, set } from "firebase/database";
import '../styles/Dashboard.css';

const balanceTheme = {
    backgroundColor: '#e7f4fc',
    inputColor: '#ffffff',
    textColor: '#333333'
};

const initialBaseSalary = 36500;
const initialStepDowns = Array(40).fill(125);
const initialEscalators = Array(40).fill(0.85);

const SalarySchedule = ({ selectedDistrictInfo }) => {
    const [baseSalary, setBaseSalary] = useState(initialBaseSalary);
    const [stepDowns, setStepDowns] = useState(initialStepDowns);
    const [escalators, setEscalators] = useState(initialEscalators);
    const [careerLadders, setCareerLadders] = useState([]);
    const [yearsToDisplay, setYearsToDisplay] = useState(40);
    const [salaryTable, setSalaryTable] = useState([]);
    const [selectedOption, setSelectedOption] = useState("New Ladder Name");
    const [newLadderName, setNewLadderName] = useState("");
    const [editLadderName, setEditLadderName] = useState("");
    const [degreeType, setDegreeType] = useState("BS");
    const [cpi, setCpi] = useState(2.5);
    const [projectToFiveYears, setProjectToFiveYears] = useState(0);
    const [displayMode, setDisplayMode] = useState('Salary');
    const [localAddOn, setLocalAddOn] = useState(0);
    const [stateAide, setStateAide] = useState(100); // Percentage, max 100
    const [showStateGrantView, setShowStateGrantView] = useState(false);
    const [calendarYear, setCalendarYear] = useState(2025);



    useEffect(() => {
        const loadSavedSchedule = async () => {
            const districtCode = selectedDistrictInfo['DISTRICT_COUNTY_CODE'];
            const scheduleRef = ref(database, `salarySchedules/${districtCode}`);
            
            try {
                const snapshot = await get(scheduleRef);
                if (snapshot.exists()) {
                    const savedData = snapshot.val();
                    setBaseSalary(savedData.baseSalary);
                    setStepDowns(savedData.stepDowns);
                    setEscalators(savedData.escalators);
                    setCareerLadders(savedData.careerLadders.map(ladder => ({
                        name: ladder.name,
                        value: ladder.stepAcross // Ensure the stepAcross value is loaded into the career ladder's 'value'
                    })));
                    setLocalAddOn(savedData.localAddOn || 0);
                    setStateAide(savedData.stateAide || 100);
                }
            } catch (error) {
                console.error("Error loading data:", error);
            }
        };
        

        loadSavedSchedule();
        createSalaryTable();
    }, [selectedDistrictInfo]);

    useEffect(() => {
        createSalaryTable();
    }, [baseSalary, stepDowns, escalators, careerLadders, yearsToDisplay, projectToFiveYears, cpi, displayMode, localAddOn, stateAide,showStateGrantView]);
    

    const createSalaryTable = () => {
        const tableData = [];
        const displayData = [];
        
        // Define Table Data
        for (let year = 1; year <= yearsToDisplay; year++) {
            const row = { year, stepDown: stepDowns[year - 1], escalator: escalators[year - 1] };
    
            careerLadders.forEach((ladder, index) => {
                let currentSalary;
                let previousSalary = index === 0 
                    ? baseSalary 
                    : parseFloat(row[careerLadders[index - 1].name].replace(/,/g, ''));
    
                if (year === 1) {
                    currentSalary = index === 0 ? baseSalary : previousSalary + ladder.value;
                } else {
                    previousSalary = parseFloat((tableData[year - 2][ladder.name] || '0').replace(/,/g, ''));
                    currentSalary = previousSalary * (1 + escalators[year - 1] / 100) + stepDowns[year - 1];
                }
    
                row[ladder.name] = Math.round(currentSalary).toLocaleString();
            });
            
            tableData.push(row);
        }
    
        // Adjust Display Data based on Display Mode
        for (let year = 1; year <= yearsToDisplay; year++) {
            const row = { year, stepDown: stepDowns[year - 1], escalator: escalators[year - 1] };
    
            careerLadders.forEach((ladder, index) => {
                let currentSalary = parseFloat((tableData[year - 1][ladder.name] || '0').replace(/,/g, ''));
                
                let previousSalary = year > 1 
                    ? parseFloat((tableData[year - 2][ladder.name] || '0').replace(/,/g, ''))
                    : 0;
                
                let previousLadderSalary = index > 0
                    ? parseFloat((tableData[year - 1][careerLadders[index - 1].name] || '0').replace(/,/g, ''))
                    : 0;
                const floor = 40000 - localAddOn
                if (showStateGrantView && currentSalary < floor) {
                        currentSalary += ((floor - currentSalary)*(stateAide/100));
                        previousSalary += ((floor - previousSalary)*(stateAide/100));
                    }
                if (showStateGrantView && previousLadderSalary < floor) {
                    previousLadderSalary += ((floor - previousLadderSalary)*(stateAide/100));
                    }
                
    
                switch (displayMode) {
                    case 'Change by Years':
                        row[ladder.name] = year > 1 ? (currentSalary - previousSalary).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-';
                        break;
                    case 'Change by Career Ladder':
                        row[ladder.name] = index > 0 ? Math.round(currentSalary - previousLadderSalary).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-';
                        break;
                    case '% Change by Years':
                        row[ladder.name] = year > 1 ? ((currentSalary - previousSalary) / previousSalary * 100).toFixed(2) + '%' : '-';
                        break;
                    case '% Change by Career Ladder':
                        row[ladder.name] = index > 0 ? ((currentSalary - previousLadderSalary) / previousLadderSalary * 100).toFixed(2) + '%' : '-';
                        break;
                    default: // Salary
                        row[ladder.name] = Math.round(currentSalary).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 });
                }
            });
            displayData.push(row);
        }
    
        setSalaryTable(displayData);
    };

    const handleInputChange = (index, field, value) => {
        const newValue = parseFloat(value) || 0;
        if (field === 'stepDown') {
            const newStepDowns = [...stepDowns];
            newStepDowns[index] = newValue;
            setStepDowns(newStepDowns);
        } else if (field === 'escalator') {
            const newEscalators = [...escalators];
            newEscalators[index] = newValue;
            setEscalators(newEscalators);
        }
    };

    const handleCareerLadderChange = (index, value) => {
        const updatedLadders = [...careerLadders];
        updatedLadders[index].value = parseFloat(value) || 0;
        setCareerLadders(updatedLadders);
    };

    const addCareerLadder = () => {
        if (newLadderName && !careerLadders.some(ladder => ladder.name === `${degreeType}${newLadderName}`)) {
            setCareerLadders([...careerLadders, { name: `${degreeType}${newLadderName}`, value: 0 }]);
            setNewLadderName("");
            setDegreeType("BS");
            setSelectedOption("New Ladder Name");
        }
    };

    const removeSelectedLadder = () => {
        setCareerLadders(careerLadders.filter((ladder) => ladder.name !== selectedOption));
        setSelectedOption("New Ladder Name");
    };

    const updateLadderName = () => {
        setCareerLadders(careerLadders.map(ladder =>
            ladder.name === selectedOption ? { ...ladder, name: editLadderName } : ladder
        ));
        setSelectedOption(editLadderName);
        setEditLadderName("");
    };

    const shouldHighlight = (value, ladderName) => {
        if (!value || displayMode !== 'Salary') return false;
    
        const numericValue = parseFloat(value.replace(/[\$,]/g, ''));
        if (isNaN(numericValue)) {
            console.warn(`Invalid numeric value for ${value}`);
            return false;
        }
    
        const currentYear = calendarYear;
        if (currentYear === 2025) return false; // No highlighting for 2025
    
        const threshold = ladderName.startsWith("BS")
            ? calculateComplianceThreshold(40000, currentYear, projectToFiveYears)
            : calculateComplianceThreshold(45000, currentYear, projectToFiveYears);
    
        return numericValue < threshold;
    };
    
    


    const calculateComplianceThreshold = (baseThreshold, currentYear, projectYears) => {
        const startYear = Math.max(currentYear, 2026); // Salary floor starts in 2026
        const floorYear = Math.min(startYear + projectYears, 2031);
        const cpiStartYear = 2032;
        const yearsFrom2025 = floorYear - 2025; // Increment calculation starts after 2025
        const incrementalIncrease = yearsFrom2025 > 0 ? yearsFrom2025 * 1000 : 0;
        const baseFloor = baseThreshold + incrementalIncrease;
    
        // Apply CPI for years beyond 2030
        if (startYear + projectYears >= cpiStartYear) {
            const cpiYears = Math.max(startYear + projectYears - cpiStartYear + 1, 0);
            return Math.round(baseFloor * Math.pow(1 + cpi / 100, cpiYears));
        }
    
        return currentYear < 2026 ? 0 : baseFloor; // No floor for 2025
    };
    
    

    const handleSaveToFirebase = async () => {
        const districtCode = selectedDistrictInfo['DISTRICT_COUNTY_CODE'];
        const dataToSave = {
            baseSalary,
            stepDowns,
            escalators,
            careerLadders: careerLadders.map(ladder => ({ name: ladder.name, stepAcross: ladder.value }))
        };

        try {
            await set(ref(database, `salarySchedules/${districtCode}`), dataToSave);
            alert("Salary schedule saved successfully!");
        } catch (error) {
            console.error("Error saving data:", error);
            alert("Failed to save salary schedule. Please try again.");
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <div>
                <p>*Still being developed, red intended to indicate lack of SB727 salary requirments</p>
            </div>
            <div style={{ backgroundColor: balanceTheme.backgroundColor, padding: '20px', borderRadius: '8px', width: '100%', margin: '0 auto' }}>
                
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: balanceTheme.textColor }}>
                    <label>Base Salary:</label>
                    <input
                        type="number"
                        value={baseSalary}
                        onChange={(e) => setBaseSalary(parseFloat(e.target.value))}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', marginRight: '20px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                        step = "50"
                    />
                    <label>Years to Display:</label>
                    <input
                        type="number"
                        value={yearsToDisplay}
                        onChange={(e) => setYearsToDisplay(parseInt(e.target.value))}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', width: '60px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    />
                    <label>Fiscal Year:</label>
    <input
        type="number"
        value={calendarYear}
        onChange={(e) => setCalendarYear(parseInt(e.target.value) || 2026)}
        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', width: '80px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
        min="2025"
        max="2035"
    />
                </div>
                
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', color: balanceTheme.textColor }}>
                    <label>CPI (%):</label>
                    <input
                        type="number"
                        value={cpi}
                        onChange={(e) => setCpi(parseFloat(e.target.value))}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', marginRight: '20px', width: '60px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    />
                    <label>Project Years Out:</label>
                    <input
                        type="range"
                        min="0"
                        max="10"
                        step="1"
                        value={projectToFiveYears ? projectToFiveYears : 0}
                        onChange={(e) => setProjectToFiveYears(parseInt(e.target.value))}
                        style={{ marginLeft: '10px', width: '100px' ,border: '1px solid #b3c7d6', borderRadius: '4px'}}
                    />
                    <span style={{ marginLeft: '10px' }}>{projectToFiveYears}</span>
                </div>
                {/* Local Add On and State Aide Input Boxes */}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', marginTop: '10px', color: balanceTheme.textColor }}>
                    <label>Local Add On:</label>
                    <input
                        type="number"
                        value={localAddOn}
                        onChange={(e) => setLocalAddOn(parseFloat(e.target.value))}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', marginRight: '20px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    />
                    <label>State Aide (%):</label>
                    <input
                        type="number"
                        value={stateAide}
                        onChange={(e) => setStateAide(Math.min(parseFloat(e.target.value), 100))}
                        style={{ backgroundColor: balanceTheme.inputColor, marginLeft: '10px', width: '60px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                    />
                </div>

                {/* State Grant View Toggle */}
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', color: balanceTheme.textColor }}>
                    <label>State & Local Aide View:</label>
                    <input
                        type="checkbox"
                        checked={showStateGrantView}
                        onChange={() => setShowStateGrantView(!showStateGrantView)}
                        style={{ marginLeft: '10px' }}
                    />
                </div>
                <h3>Manage Career Ladders</h3>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                    <select
                        onChange={(e) => {
                            setSelectedOption(e.target.value);
                            setEditLadderName(e.target.value); 
                        }}
                        value={selectedOption}
                        style={{ backgroundColor: balanceTheme.inputColor, marginRight: '10px', padding: '5px', width: '150px' ,border: '1px solid #b3c7d6', borderRadius: '4px'}}
                    >
                        <option value="New Ladder Name">New Ladder Name</option>
                        {careerLadders.map((ladder, index) => (
                            <option key={index} value={ladder.name}>{ladder.name}</option>
                        ))}
                    </select>
                    {selectedOption === "New Ladder Name" ? (
                        <>
                            <select
                                onChange={(e) => setDegreeType(e.target.value)}
                                value={degreeType}
                                style={{ backgroundColor: balanceTheme.inputColor, marginRight: '10px', padding: '5px', width: '80px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                            >
                                <option value="BS">BS</option>
                                <option value="MS">MS</option>
                            </select>
                            <input
                                type="text"
                                placeholder="Years Experience"
                                value={newLadderName}
                                onChange={(e) => setNewLadderName(e.target.value)}
                                style={{ backgroundColor: balanceTheme.inputColor, marginRight: '10px', padding: '5px', width: '120px',border: '1px solid #b3c7d6', borderRadius: '4px' }}
                            />
                            <button onClick={addCareerLadder}>Add Ladder</button>
                        </>
                    ) : (
                        <>
                            <input
                                type="text"
                                value={editLadderName}
                                onChange={(e) => setEditLadderName(e.target.value)}
                                style={{ backgroundColor: balanceTheme.inputColor, marginRight: '10px', padding: '5px', width: '120px' ,border: '1px solid #b3c7d6', borderRadius: '4px'}}
                            />
                            <button onClick={updateLadderName}>Update Name</button>
                            <button onClick={removeSelectedLadder} style={{ marginLeft: '10px',border: '1px solid #b3c7d6', borderRadius: '4px' }}>Remove Selected Ladder</button>
                        </>
                    )}
                </div>
                
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
                    {['Salary', 'Change by Years', '% Change by Years', 'Change by Career Ladder', '% Change by Career Ladder'].map((mode, index) => (
                        <button
                            key={index}
                            onClick={() => setDisplayMode(mode)}
                            style={{
                                backgroundColor: displayMode === mode ? '#007BFF' : '#e7f4fc',
                                color: displayMode === mode ? '#fff' : '#333',
                                border: '1px solid #b3c7d6',
                                borderRadius: '4px',
                                padding: '5px',
                                cursor: 'pointer',
                                margin: '0 5px'
                            }}
                        >
                            {mode}
                        </button>
                    ))}
                </div>


                <button onClick={handleSaveToFirebase} style={{ marginTop: '20px', padding: '10px', backgroundColor: '#007BFF', color: '#fff', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
                    Save Salary Schedule
                </button>
                
                <table className="salary-table" style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
                    <thead>
                        <tr>
                            <th>Step Down ($)</th>
                            <th>Escalator (%)</th>
                            <th>Year</th>
                            {careerLadders.map((ladder) => (
                                <th key={ladder.name}>{ladder.name}</th>
                            ))}
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            {careerLadders.map((ladder, index) => (
                                <td key={index}>
                                    <input
                                        type="number"
                                        value={ladder.value}
                                        onChange={(e) => handleCareerLadderChange(index, e.target.value)}
                                        style={{ backgroundColor: balanceTheme.inputColor, width: '60px', border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                        placeholder="Step Across"
                                    />
                                </td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {salaryTable.map((row, index) => (
                            <tr key={index}>
                                <td>
                                    <input
                                        type="number"
                                        value={row.stepDown}
                                        style={{ backgroundColor: balanceTheme.inputColor, border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                        onChange={(e) => handleInputChange(index, 'stepDown', e.target.value)}
                                    />
                                </td>
                                <td>
                                    <input
                                        type="number"
                                        value={row.escalator}
                                        style={{ backgroundColor: balanceTheme.inputColor, border: '1px solid #b3c7d6', borderRadius: '4px' }}
                                        onChange={(e) => handleInputChange(index, 'escalator', e.target.value)}
                                    />
                                </td>
                                <td>{row.year}</td>
                                {careerLadders.map((ladder) => (
                                    <td 
                                        key={ladder.name} 
                                        style={{ backgroundColor: shouldHighlight(row[ladder.name], ladder.name) ? '#ffcccc' : 'transparent' }}
                                    >
                                        {row[ladder.name]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SalarySchedule;

