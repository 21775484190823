import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth} from "firebase/auth";

// Firebase Admin SDK configuration
const firebaseConfig = {
    apiKey: "AIzaSyDJxwBrqQRyurZMVRMTxHwkBRN_JE9HQrE",
    authDomain: "balancehq-5ac3f.firebaseapp.com",
    databaseURL: "https://balancehq-5ac3f-default-rtdb.firebaseio.com",
    projectId: "balancehq-5ac3f",
    storageBucket: "balancehq-5ac3f.appspot.com",
    messagingSenderId: "398514114392",
    appId: "1:398514114392:web:ffb9f56d3f39cb8c699f2d",
    measurementId: "G-G51KJR3LXP"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const auth = getAuth(app);
