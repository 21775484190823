import React, { useState, useEffect } from 'react';
import Card from './Card';
import '../styles/Dashboard.css';

const RevenueDash = ({ selectedDistrictInfo, boardView, dashboardData}) => {
    const [schoolData, setSchoolData] = useState({});

    const maxYear = Math.max(...Object.keys(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));

    // Helper functions
    const formatPrecision = (num, precision) => {
        return num ? parseFloat(num).toFixed(precision) : 'N/A';
    };

    const formatDollars = (num) => {
        return `$${Math.round(num).toLocaleString()}`;
    };

    function calculateAverage(array) {
        if (array.length === 0) {
          return 0;
        }
      
        const sum = array.reduce((acc, num) => acc + num, 0);
        return sum / array.length;
    }

    const calcYoYchange = (num_old, num_new) => {
        return (100 * (num_new - num_old) / num_old).toFixed(0);
    };

    const getArrowDirection = (num) => {
        if (num > 0) return "up";
        if (num < 0) return "down";
        return "flat";
    };

    selectedDistrictInfo['peer_LOCAL_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['REVENUE_PERCENT_LOCAL']));
    selectedDistrictInfo['peer_COUNTY_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['REVENUE_PERCENT_COUNTY']));
    selectedDistrictInfo['peer_STATE_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['REVENUE_PERCENT_STATE']));
    selectedDistrictInfo['peer_FEDERAL_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['REVENUE_PERCENT_FEDERAL']));


    return (
        <div className="dashboard">
            <div className="title">Revenue</div>

            <div className="dashboard-cards">
            <Card
                defaultSchool={selectedDistrictInfo}
                title="Local Revenue Share"
                value={`${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].REVENUE_PERCENT_LOCAL}%` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].REVENUE_PERCENT_LOCAL,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].REVENUE_PERCENT_LOCAL) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_LOCAL_avg,1) || "0"}%`}
                bgColor="#88b0d8"
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].REVENUE_PERCENT_LOCAL,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].REVENUE_PERCENT_LOCAL))}`}
                definition="The percentage of total school revenue that comes from local sources such as property taxes, sales taxes, and other local contributions."
                graphMetric="REVENUE_PERCENT_LOCAL"
                yAxisTitle='Percent (%)'
                boardView={boardView}
                graphData={dashboardData}
            />
            <Card
                defaultSchool={selectedDistrictInfo}
                title="County Revenue Share"
                value={`${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].REVENUE_PERCENT_COUNTY}%` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].REVENUE_PERCENT_COUNTY,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].REVENUE_PERCENT_COUNTY) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_COUNTY_avg,1) || "0"}%`}
                bgColor="#d8abc1" 
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].REVENUE_PERCENT_COUNTY,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].REVENUE_PERCENT_COUNTY))}`}
                definition="The percentage of total school revenue that is derived from county-level funding."
                graphMetric="REVENUE_PERCENT_COUNTY"
                yAxisTitle='Percent (%)'
                boardView={boardView}
                graphData={dashboardData}
            />
            <Card
                defaultSchool={selectedDistrictInfo}
                title="State Revenue Share"
                value={`${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].REVENUE_PERCENT_STATE}%` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].REVENUE_PERCENT_STATE,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].REVENUE_PERCENT_STATE) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_STATE_avg,1) || "0"}%`}
                bgColor="#87b6a7" 
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].REVENUE_PERCENT_STATE,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].REVENUE_PERCENT_STATE))}`}
                definition="The percentage of total school revenue that is funded by federal programs, including Title I, special education funding, and other federal grants."
                graphMetric="REVENUE_PERCENT_STATE"
                yAxisTitle='Percent (%)'
                boardView={boardView}
                graphData={dashboardData}
            />
            <Card
                defaultSchool={selectedDistrictInfo}
                title="Federal Revenue Share"
                value={`${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].REVENUE_PERCENT_FEDERAL}%` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].REVENUE_PERCENT_FEDERAL,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].REVENUE_PERCENT_FEDERAL) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_FEDERAL_avg,1) || "0"}%`}
                bgColor="#f79f79" 
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].REVENUE_PERCENT_FEDERAL,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].REVENUE_PERCENT_FEDERAL))}`}
                definition="The assessed value is the total value of all taxable property (like homes, businesses, and land) within the school district. This value helps determine how much money the district can collect from local property taxes, which is a major source of funding for your district."
                graphMetric="REVENUE_PERCENT_FEDERAL"
                yAxisTitle='Percent (%)'
                boardView={boardView}
                graphData={dashboardData}
            />
            </div>
        </div>
    );
};

export default RevenueDash;
