import React, { useEffect, useState } from 'react';
import { auth } from '../firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import AuthenticationCard from './AuthenticationCard';

const AuthWrapper = ({ children, onLogin, userFound, isFirstLogin}) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        onLogin(user.email); // Pass the email to the parent component
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [onLogin]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!userFound || isFirstLogin){
    return <AuthenticationCard userFound={userFound} isFirstLogin={isFirstLogin}/>;
  }
  
  return children;
};

export default AuthWrapper;
