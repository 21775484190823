import React from 'react';
import '../styles/LoadingPage.css'; // Import the CSS file for styling
import logo from '../logo.png'; 

const LoadingPage = ({ isExiting }) => {
  return (
    <div className={`loading-container ${isExiting ? 'exit' : ''}`}>
      <div className="logo-container">
        <img src={logo} alt="BalanceHQ" className="logo-loading" />
      </div>
      <div className="spinner"></div>
    </div>
  );
};

export default LoadingPage;
