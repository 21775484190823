import React, { useState, useEffect } from 'react';
import Card from './Card';
import '../styles/Dashboard.css';

const AcademicsDash = ({ selectedDistrictInfo, boardView, dashboardData }) => {
    const [schoolData, setSchoolData] = useState({});

    const maxYear = Math.max(...Object.keys(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));

    // Helper functions
    const formatPrecision = (num, precision) => {
        return num ? parseFloat(num).toFixed(precision) : 'N/A';
    };

    const formatDollars = (num) => {
        return `$${Math.round(num).toLocaleString()}`;
    };

    function calculateAverage(array) {
        if (array.length === 0) {
          return 0;
        }
      
        const sum = array.reduce((acc, num) => acc + num, 0);
        return sum / array.length;
    }

    const calcYoYchange = (num_old, num_new) => {
        return (100 * (num_new - num_old) / num_old).toFixed(0);
    };

    const getArrowDirection = (num) => {
        if (num > 0) return "up";
        if (num < 0) return "down";
        return "flat";
    };

    selectedDistrictInfo['peer_ACT_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['ACT_COMPOSITE_SCORE']));
    selectedDistrictInfo['peer_PROPATTEND_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['PROPORTIONAL_ATTENDANCE_TOTAL_PCT']));
    selectedDistrictInfo['peer_4YRGRADRATE_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['GRADUATION_RATE_4YR_COHORT']));
    selectedDistrictInfo['peer_ACTREADING_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['ACT_READING_SCORE']));

    return (
        <div className="dashboard">
            <div className="title">Academics</div>

            <div className="dashboard-cards">
            <Card
                defaultSchool={selectedDistrictInfo}
                title="ACT Composite Score"
                value={`${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].ACT_COMPOSITE_SCORE}` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].ACT_COMPOSITE_SCORE,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].ACT_COMPOSITE_SCORE) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_ACT_avg,1) || "0"}`}
                bgColor="#88b0d8"
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].ACT_COMPOSITE_SCORE,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].ACT_COMPOSITE_SCORE))}`}
                definition="The average composite score of all students taking the ACT exam, representing overall student performance across subjects like English, math, reading, and science."
                graphMetric="ACT_COMPOSITE_SCORE"
                yAxisTitle='Score'
                boardView={boardView}
                graphData={dashboardData}
            />
            <Card
                defaultSchool={selectedDistrictInfo}
                title="Attendance"
                value={`${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].PROPORTIONAL_ATTENDANCE_TOTAL_PCT}%` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].PROPORTIONAL_ATTENDANCE_TOTAL_PCT,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].PROPORTIONAL_ATTENDANCE_TOTAL_PCT) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_PROPATTEND_avg,1) || "0"}%`}
                bgColor="#d8abc1" 
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].PROPORTIONAL_ATTENDANCE_TOTAL_PCT,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].PROPORTIONAL_ATTENDANCE_TOTAL_PCT))}`}
                definition="Proportioanl Attendance Total Percent. The percentage of enrolled students attending school on a regular basis, often used as a measure of student engagement and institutional effectiveness."
                graphMetric="PROPORTIONAL_ATTENDANCE_TOTAL_PCT"
                yAxisTitle='Percent (%)'
                boardView={boardView}
                graphData={dashboardData}
            />
            <Card
                defaultSchool={selectedDistrictInfo}
                title="Graduation Rate" 
                value={`${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].GRADUATION_RATE_4YR_COHORT}%` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].GRADUATION_RATE_4YR_COHORT,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].GRADUATION_RATE_4YR_COHORT) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_4YRGRADRATE_avg,1) || "0"}%`}
                bgColor="#87b6a7"
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].GRADUATION_RATE_4YR_COHORT,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].GRADUATION_RATE_4YR_COHORT))}`}
                definition="Graduation rate 4yr Cohort. The percentage of students who graduate within four years of starting high school, a key indicator of school performance and student success."
                graphMetric="GRADUATION_RATE_4YR_COHORT"
                yAxisTitle='Percent (%)'
                boardView={boardView}
                graphData={dashboardData}
            />
            <Card
                defaultSchool={selectedDistrictInfo}
                title="ACT Reading Score" 
                value={`${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].ACT_READING_SCORE}` || "0"} 
                YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].ACT_READING_SCORE,
                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].ACT_READING_SCORE) + '% YoY' || '0% YoY'}
                smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_ACTREADING_avg,1) || "0"}`}
                bgColor="#f79f79"
                arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].ACT_READING_SCORE,
                                                                    dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].ACT_READING_SCORE))}`}
                definition="The average reading section score for all students taking the ACT exam, indicative of student reading comprehension and literacy skills."
                graphMetric="ACT_READING_SCORE"
                yAxisTitle='Score'
                boardView={boardView}
                graphData={dashboardData}
            />
            </div>
        </div>
    );
};

export default AcademicsDash;
