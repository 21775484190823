import React, { useState, useEffect } from 'react';
import Card from './Card';
import '../styles/Dashboard.css';

const FacultyDash = ({ selectedDistrictInfo, boardView, dashboardData }) => {
    const [schoolData, setSchoolData] = useState({});

    const maxYear = Math.max(...Object.keys(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));

    // Helper functions
    const formatPrecision = (num, precision) => {
        return num ? parseFloat(num).toFixed(precision) : 'N/A';
    };

    const formatDollars = (num) => {
        return `$${Math.round(num).toLocaleString()}`;
    };

    function calculateAverage(array) {
        if (array.length === 0) {
          return 0;
        }
      
        const sum = array.reduce((acc, num) => acc + num, 0);
        return sum / array.length;
    }

    const calcYoYchange = (num_old, num_new) => {
        return (100 * (num_new - num_old) / num_old).toFixed(0);
    };

    const getArrowDirection = (num) => {
        if (num > 0) return "up";
        if (num < 0) return "down";
        return "flat";
    };

    selectedDistrictInfo['peer_TEACHSALARY_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['TEACHER_SALARY_AVG_REG_TERM']));
    selectedDistrictInfo['peer_STUDENTTEACHERRATIO_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['STUDENTS_PER_TEACHER_RATIO']));
    selectedDistrictInfo['peer_STUDENTADMINRATIO_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['STUDENTS_PER_ADMIN_RATIO']));
    selectedDistrictInfo['peer_PROFDEVEL_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['BUDGET_PERCENT_PROFESSIONAL_DEVELOPMENT']));

    return (
        <div className="dashboard">
            <div className="title">Faculty</div>

            <div className="dashboard-cards">
                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="Avg. Teacher Salary"
                    value={`${formatDollars(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].TEACHER_SALARY_AVG_REG_TERM)}` || "0"} 
                    YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].TEACHER_SALARY_AVG_REG_TERM,
                                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].TEACHER_SALARY_AVG_REG_TERM) + '% YoY' || '0% YoY'}
                    smallText={`Peers: ${formatDollars(selectedDistrictInfo.peer_TEACHSALARY_avg) || "0"}`}
                    bgColor="#88b0d8"
                    arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].TEACHER_SALARY_AVG_REG_TERM,
                                                                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].TEACHER_SALARY_AVG_REG_TERM))}`}
                    definition="The average salary paid to certificated teachers in the district during the regular term."
                    graphMetric="TEACHER_SALARY_AVG_REG_TERM"
                    yAxisTitle='Dollars ($)'
                    boardView={boardView}
                    graphData={dashboardData}
                />
                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="Student Teacher Ratio" 
                    value={`${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].STUDENTS_PER_TEACHER_RATIO}` || "0"} 
                    YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].STUDENTS_PER_TEACHER_RATIO,
                                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].STUDENTS_PER_TEACHER_RATIO) + '% YoY' || '0% YoY'}
                    smallText={`Peers: ${selectedDistrictInfo.peer_STUDENTTEACHERRATIO_avg || "0"}`}
                    bgColor="#d8abc1"
                    arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].STUDENTS_PER_TEACHER_RATIO,
                                                                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].STUDENTS_PER_TEACHER_RATIO))}`}
                    definition="The number of students per full-time equivalent teacher, a measure of class size and instructional resource allocation."
                    graphMetric="STUDENTS_PER_TEACHER_RATIO"
                    yAxisTitle='Ratio'
                    boardView={boardView}
                    graphData={dashboardData}
                />
                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="Student Admin Ratio" 
                    value={`${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].STUDENTS_PER_ADMIN_RATIO}` || "0"} 
                    YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].STUDENTS_PER_ADMIN_RATIO,
                                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].STUDENTS_PER_ADMIN_RATIO) + '% YoY' || '0% YoY'}
                    smallText={`Peers: ${selectedDistrictInfo.peer_STUDENTADMINRATIO_avg || "0"}`}
                    bgColor="#87b6a7"
                    arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].STUDENTS_PER_ADMIN_RATIO,
                                                                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].STUDENTS_PER_ADMIN_RATIO))}`}
                    definition="The number of students per full-time equivalent administrator, reflecting the balance between student population and administrative oversight."
                    graphMetric="STUDENTS_PER_ADMIN_RATIO"
                    yAxisTitle='Ratio'
                    boardView={boardView}
                    graphData={dashboardData}
                />
                <Card
                    defaultSchool={selectedDistrictInfo}
                    title="Professional Development % of Budget" 
                    value={`${dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_PROFESSIONAL_DEVELOPMENT}%` || "0"} 
                    YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_PROFESSIONAL_DEVELOPMENT,
                                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_PROFESSIONAL_DEVELOPMENT) + '% YoY' || '0% YoY'}
                    smallText={`Peers: ${formatPrecision(selectedDistrictInfo.peer_PROFDEVEL_avg,0)}%` || "0"}
                    bgColor="#f79f79"
                    arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_PROFESSIONAL_DEVELOPMENT,
                                                                        dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_PROFESSIONAL_DEVELOPMENT))}`}
                    definition="The percentage of the total school budget allocated to professional development programs aimed at enhancing staff and teacher skills (line items 2211-2219 and 2214)."
                    graphMetric="BUDGET_PERCENT_PROFESSIONAL_DEVELOPMENT"
                    yAxisTitle='Percent (%)'
                    boardView={boardView}
                    graphData={dashboardData}
                />
            </div>
        </div>
    );
};

export default FacultyDash;
